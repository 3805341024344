import axios from "axios";



const ServicesHome = async (lang) => {
    const url = `${process.env.REACT_APP_API_URL}/home/services`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
}; 
const ProjectServices = async (lang, value) => {
    const url = `${process.env.REACT_APP_API_URL}/home/projects?category=${value}`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
}; 
const ProjectDetailsServices = async (lang, id) => {
    const url = `${process.env.REACT_APP_API_URL}/home/projects/${id}`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': lang,
        }
    }).catch((err) => {
    })
    return data
};


export { ProjectServices, ProjectDetailsServices, ServicesHome }