import AOS from 'aos';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet-async';
import AboutServices from '../../Services/AboutServices';
import LocalizationData from '../../Translate/LocalizationData';
import img from '../../assets/Img';
import { LocalizationContext } from '../../context/LangChange';
import '../Home/About/style.scss';
import Component from './../../constants/Component';

const About = () => {
  let { isLang } = useContext(LocalizationContext)
  const [aboutData, setAboutData] = useState([])
  const [aboutData2, setAboutData2] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scroll(0, 0) 

    const timeOut = setTimeout(() => {
      setloading(false)
      AboutServices(isLang, -1).then(({ data }) => {
        if (data?.status === 200) {
          setloading(true)
          setAboutData(data?.data)
          setAboutData2(data?.data?.about_data)
        }
      }).catch((error) => {
      });
    }, 200);
    return () => clearTimeout(timeOut);
  }, [isLang])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isLang === "en" ? 'About Us | Right Click - Digital Marketing Agency in Saudi Arabia' : 'من نحن | رايت كليك- وكالة تسويق رقمي في السعودية'}</title>
        <meta name="description" content={isLang === "en" ? 'Learn more about Right Click, a leading digital marketing agency in Saudi Arabia. Discover our services, achievements, and how we can help your business grow.' : 'تعرف على رايت كليك، وكالة التسويق الرقمي الرائدة في السعودية. اكتشف خدماتنا وإنجازاتنا وكيف يمكننا مساعدة عملك على النمو.'} />
        <meta name="keywords" content={isLang === "en" ? "Digital Marketing, Saudi Arabia, Right Click, SEO, Social Media, Content Marketing, PPC, Marketing Agency" : ''} />
        <meta name="author" content="Right Click" />
        <meta property="og:title" content={isLang === "en" ? 'About Us | Right Click - Digital Marketing Agency in Saudi Arabia' : 'من نحن | رايت كليك - وكالة تسويق رقمي في السعودية'} />
        <meta property="og:description" content={isLang === "en" ? 'Learn more about Right Click, a leading digital marketing agency in Saudi Arabia. Discover our services, achievements, and how we can help your business grow.' : 'تعرف على رايت كليك، وكالة التسويق الرقمي الرائدة في السعودية. اكتشف خدماتنا وإنجازاتنا وكيف يمكننا مساعدة عملك على النمو.'} />
        <meta property="og:image" content={img.HeaderAboutUS} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {
        loading ?
          <>
            <Component.HeaderPages
              img={img.HeaderAboutUS}
              title={aboutData?.section_title}
              text={aboutData?.section_content}
            />
            <div className='overflow-hidden'>
              <Container className='app__about mt-3  ' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                <div className="summary mb-5 d-flex gap-5">
                  <div className="project" data-aos="fade-left" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                    <span className="title">
                      {LocalizationData[isLang]?.About.summaryProject}
                    </span>
                    <span className="score mt-2" dir='ltr'>
                      {'+'}<CountUp start={0} end={aboutData?.projects || 0} duration={5} delay={0.5} />
                    </span>
                  </div>
                  <div className="project" data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                    <span className="title"> {LocalizationData[isLang]?.About.summaryClients} </span>
                    <span className="score mt-2" dir='ltr' >
                      {'+'}<CountUp start={0} end={aboutData?.clients || 0} duration={7} delay={1.5} />
                    </span>
                  </div>
                  <div className="project" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                    <span className="title">
                      {LocalizationData[isLang]?.About.summaryExperience}
                    </span>
                    <span className="score mt-2" dir='ltr' >
                      {'+'}<CountUp start={0} end={aboutData?.experience || 0} duration={7} delay={1.5} />
                      <span className="years mt-3">
                        {LocalizationData[isLang]?.About.summaryYear}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="app__Who_we_Are">
                  {aboutData2?.map((item, index) => (
                    <React.Fragment key={index}>
                      {index % 2 !== 0 ? (
                        <Row className='content-rtl'>
                          <Col className='m-0 p-0' xl={6} lg={6} md={12} sm={12} data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                            <div className="content">
                              <span>{item?.title}</span>
                              <p>{item?.description}</p>
                            </div>
                          </Col>
                          <Col key={index} xl={6} lg={6} md={12} sm={12} className="m-0 p-0" data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                            <img src={item.image} width={'100%'} height={'500px'} alt="image_aboutus" />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col key={index} xl={6} lg={6} md={12} sm={12} className="m-0 p-0" data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                            <img src={item.image} width={'100%'} height={'500px'} alt="image_aboutus" />
                          </Col>
                          <Col className='m-0 p-0' xl={6} lg={6} md={12} sm={12} data-aos="fade-left" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                            <div className="content">
                              <span>{item?.title}</span>
                              <p>{item?.description}</p>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Container>
              <Component.Footer />
            </div>
          </> :
          <Component.LoadingPage />
      }
    </>
  )
}

export default About
