import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { ServicesHome } from '../../Services/ProjectServices';
import LocalizationData from '../../Translate/LocalizationData';
import img from '../../assets/Img';
import Component from '../../constants/Component';
import Icons from '../../constants/Icons';
import { LocalizationContext } from '../../context/LangChange';
import './Services.scss';

const Services = () => {
  let { isLang } = useContext(LocalizationContext);
  const [activeService, setActiveService] = useState('');
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const handleServiceClick = (service) => {
    setActiveService(service);
    localStorage.setItem('activeService', service);
  };
  const [data, setData] = useState([])
  const [services, setServices] = useState([])
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    const storedActiveService = localStorage.getItem('activeService');
    if (!storedActiveService) {
      setActiveService('ourServices');
      localStorage.setItem('activeService', 'ourServices');
    } else {
      setActiveService(storedActiveService);
    }
    const timeOut = setTimeout(() => {
      setisLoading(false)
      ServicesHome(isLang).then(({ data }) => {
        if (data?.status === 200) {
          setData(data?.data)
          setServices(data?.data?.services)
          setisLoading(true)
        }
      }).catch((error) => {
      });
    }, 200);
    return () => clearTimeout(timeOut);
  }, [isLang])
  const renderServiceContent = (service, title, des, id) => {
    return (
      activeService === service && (
        <>
          <Component.Title duration_p={1.5} id='serive-tiitle' title={title} textStyle={'text-left  '}
            width={"w-100"}
            text={des}
          />
          <Link to={isLang == "en" ? `/service/${id}` : `/ar/خدماتنا/${id}`} className='  mt-5'>
            {
              localStorage.getItem('activeService') == "ourServices" ? '' :
                <Button
                  className='btn__overlay'
                  variant="contained"
                  color="warning"
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#FA6409',
                    color: '#24282C',

                    '&:hover': {
                      backgroundColor: '#000000',
                      color: '#ffffff',
                    },
                    fontSize: `16px !important`
                  }}
                >
                  {isLang === "en" ? 'Details' : 'عرض المزيد'}
                </Button>
            }
          </Link>
        </>
      )
    );
  };
  
  const pageTitle = pathname
    ?.replace('/ar/', '')
    ?.replace('/service', 'Service')
    ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
    ?.replace(/-/g, ' '); // Replace all hyphens with spaces

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {
        isLoading ?
          <>
            <Component.HeaderPages
              img={img.HeaderService}
              title={pageTitle}
              text={LocalizationData[isLang]?.Service.Text}
            />
            <div className='  bg-light' style={{ paddingTop: '40px' }} /* style={{ position: 'relative', height: '100vh' }} */>

              <Container className='mb-5'>
                <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                  <Col dir={'ltr'} xl={6} lg={6} md={12} sm={12} className="app__services">
                    <div className="all__service m-0 p-0 ">
                      <div className="Services1 w-100">
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('web')}
                          className={`${activeService === 'web' ? `item1 ${isLang === 'ar' && 'item1Ar'}  click__item` : `item1 ${isLang === 'ar' && 'item1Ar'} `}`}  >
                          <Icons.Service3 style={{ width: '40px', height: '40px' }} />
                          <span >
                            {services[5]?.title.split(' ').map((word, index) => (
                              <React.Fragment key={index}>
                                {word}
                                <br />
                              </React.Fragment>
                            ))}

                          </span>
                        </motion.div>
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('digitalMarketing')}
                          className={`${activeService === 'digitalMarketing' ? `item2 click__item ${isLang === 'ar' && 'item1Ar'}` : `item2 ${isLang === 'ar' && 'item1Ar'}`}`} >
                          <Icons.Service1 style={{ width: '40px', height: '40px' }} />
                          <span>{services[2]?.title.split(' ').map((word, index) => (
                            <React.Fragment key={index}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                          </span>
                        </motion.div>
                      </div>
                      <div className="Services2 w-100">
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('visualProduction')}
                          className={`${activeService === 'visualProduction' ? `item3 click__item ${isLang === 'ar' && 'item1Ar'}` : `item3 ${isLang === 'ar' && 'item1Ar'}`}`}  >
                          <Icons.Service6 style={{ width: '40px', height: '40px' }} />
                          <span>{services[1]?.title.split(' ').map((word, index) => (
                            <React.Fragment key={index}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                          </span>
                        </motion.div>
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ scale: [0, 1], transition: { duration: 1 } }}
                          onClick={() => handleServiceClick('ourServices')}
                          className={`${activeService === 'ourServices' ? `item4 click__item-service ${isLang === 'ar' && 'item1Ar'}` : `item4 ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Cloud />
                          <span>{data?.section_title}</span>

                        </motion.div>
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('Event')}
                          className={`${activeService === 'Event' ? `item5 click__item Services2_lg ${isLang === 'ar' && 'item1Ar'}` : `item5 Services2_lg ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Service5 style={{ width: '40px', height: '40px' }} />
                          <span>{services[0]?.title}</span>
                        </motion.div>
                      </div>
                      <div className="Services3 w-100">
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('mobilApp')}
                          className={`${activeService === 'mobilApp' ? `item6 click__item ${isLang === 'ar' && 'item1Ar'}` : `item6 ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Mobile style={{ width: '40px', height: '40px' }} />
                          <span  > {services[4]?.title}
                          </span>
                        </motion.div>
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9, delay: 1 } }}
                          onClick={() => handleServiceClick('VisualIdentity')}
                          className={`${activeService === 'VisualIdentity' ? `item7 click__item Services2_lg ${isLang === 'ar' && 'item1Ar'}` : `item7 Services2_lg ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Service4 style={{ width: '40px', height: '40px' }} />
                          <span> {services[3]?.title.split(' ').map((word, index) => (
                            <React.Fragment key={index}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                          </span>
                        </motion.div>
                      </div>

                      {/* small screen */}
                      <div className="Services4    w-100">
                        <motion.div
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9 } }}
                          onClick={() => handleServiceClick('Event')}
                          className={`${activeService === 'Event' ? `item8 click__item ${isLang === 'ar' && 'item1Ar'}` : `item8 ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Service5 style={{ width: '40px', height: '40px' }} />
                          <span>{services[0]?.title.split(' ').map((word, index) => (
                            <React.Fragment key={index}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                          </span>
                        </motion.div>
                        <motion.div
                          dir={isLang === "en" ? 'ltr' : 'rtl'}
                          whileInView={{ opacity: [0, 1], transition: { duration: 0.9 } }}
                          onClick={() => handleServiceClick('VisualIdentity')}
                          className={`${activeService === 'VisualIdentity' ? `item9 click__item ${isLang === 'ar' && 'item1Ar'}` : `item9 ${isLang === 'ar' && 'item1Ar'}`}`}
                        >
                          <Icons.Service4 style={{ width: '40px', height: '40px' }} />
                          <span>{services[3]?.title.split(' ').map((word, index) => (
                            <React.Fragment key={index}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                          </span>
                        </motion.div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} className="mt-5 ">
                    <div className="app__services-content">
                      {renderServiceContent('web', services[5]?.title, services[5]?.description, isLang == "en" ? services[5]?.key_word : services[5]?.key_word_ar)}
                      {renderServiceContent('digitalMarketing', services[2]?.title, services[2]?.description, isLang == "en" ? services[2]?.key_word : services[2]?.key_word_ar)}
                      {renderServiceContent('visualProduction', services[1]?.title, services[1]?.description, isLang == "en" ? services[1]?.key_word : services[1]?.key_word_ar)}
                      {renderServiceContent('ourServices', data?.section_title, data?.section_content)}
                      {renderServiceContent('Event', services[0]?.title, services[0]?.description, isLang == "en" ? services[0]?.key_word : services[0]?.key_word_ar)}
                      {renderServiceContent('mobilApp', services[4]?.title, services[4]?.description, isLang == "en" ? services[4]?.key_word : services[4]?.key_word_ar)}
                      {renderServiceContent('VisualIdentity', services[3]?.title, services[3]?.description, isLang == "en" ? services[3]?.key_word : services[3]?.key_word_ar)}
                    </div>
                  </Col>
                </Row>
              </Container>
              <Component.Footer />

            </div>
          </> : <Component.LoadingPage />
      }
    </>
  );
};

export default Services;
