import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Component from '../constants/Component';

const RightClick = () => {
    const location = useLocation();
    // let { loading } = useContext(LocalizationContext);


    const [navBarBg, setnavBarBg] = useState('navbar-nav')
    
    useEffect(() => {
        if (location.pathname !== '/') {
            setnavBarBg('navbar-nav');
        } else {
            setnavBarBg('navbar-navOnthorPAge')
        }
    }, [location.pathname])

    return (

        <>
            <div className="loading__page">
                <Component.NavBar navbarColor={navBarBg} />
                <div style={{ position: 'relative' }}  >
                    <Component.Whatsapp />
                    <Outlet></Outlet>
                </div>
            </div>
        </>
    )
}

export default RightClick