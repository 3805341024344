import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import img from '../../assets/Img';
import Icons from '../../constants/Icons';
import { FetchDataContext } from '../../context/FetchData';
import { LocalizationContext } from '../../context/LangChange';
import './navbar.scss';
import routes from './routes';

const NavBar = ({ navbarColor }) => {
    let { isLang, setIsLang } = useContext(LocalizationContext);
    let { settings } = useContext(FetchDataContext)

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const [activeLink, setActiveLink] = useState(0);

    const handleLinkClick = (index) => {
        setActiveLink(index);
        setMenuOpen(false)
    };
    const menuVariants = {
        hidden: {
            opacity: 0,
            x: isLang === "en" ? '100%' : '-100%',
            transition: {
                duration: 0.6,
            },
        },
        visible: {
            opacity: 1,
            x: '0%',
            transition: {
                duration: 0.6,
            },
        },
        exit: {
            opacity: 0,
            x: '100%',
            transition: {
                duration: 0.6,
            },
        },
    };
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate(); // Get the navigation function

    const handleLocalizationChange = async (action) => {
        if (action === 'en') {
            setIsLang('en')
            navigate('/home');
        } else if (action === 'ar') {
            setIsLang('ar')
            navigate('/ar');
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const location = useLocation(); 



    return (
        <>
            <AnimatePresence >
                {scrollPosition <= 400 && (
                    <motion.div
                        dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}
                        className="navbar__social "
                        initial={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}>
                        <Navbar className={`px-4 navbar__social-Container `} >
                            <div className="calling">
                                <motion.a
                                    href={`mailto: ${settings?.email}`}
                                    target='_blank'
                                    rel="noreferrer"
                                    whileHover={{ y: -5, scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Message />
                                </motion.a>

                                <motion.a
                                    href={`tel:${settings?.whats}`}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ y: -5, scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Phone className='navbar-sm-icons-phone' />
                                </motion.a>
                            </div>
                            <div className={`${isLang === "en" ? 'center' : 'center center-rtl'}`}>
                                <motion.div
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Link to={'/'} className='nav1__home_svg'>
                                        <Icons.HomeOutline className='Home' />
                                    </Link>
                                </motion.div>


                                {
                                    isLang === "en" ?
                                        <div className="change-lang  " onClick={(eventKey) => handleLocalizationChange('ar')}>
                                            <span className='text ' style={{ marginBottom: '4px' }}>  عربيه</span>
                                            <Icons.LangOutline />
                                        </div> :
                                        <div className="change-lang" onClick={(eventKey) => handleLocalizationChange('en')}>
                                            <span className='text ' /* style={{ marginBottom: '2px' }} */>  English</span>
                                            <Icons.LangOutline />

                                        </div>
                                }


                            </div>
                            <div className="social">
                                <motion.a
                                    href={settings?.youtube}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Youtube color={'#ffff'} />
                                </motion.a>
                                <motion.a
                                    href={settings?.twitter}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Twitter color={'#ffff'} />
                                </motion.a>


                    {/*             <motion.a
                                    href={settings?.facebook}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Facebook color={'#ffff'} className='facebook' />
                                </motion.a> */}

                                <motion.a
                                    href={settings?.instagram}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Instagram color={'#ffff'} />
                                </motion.a>

                                <motion.a
                                    href={settings?.linked_in}
                                    target='_blank' rel="noreferrer"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Icons.Linkedin color={'#ffff'} />
                                </motion.a>
                            </div>
                        </Navbar>
                    </motion.div>
                )}
            </AnimatePresence >
            <div className="main-nav con">
                <Navbar
                    dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}
                    fixed={scrollPosition > 400 ? 'top' : 'top'}
                    collapseOnSelect
                    expand="sm"
                    className={`px-3  ${navbarColor}  py-3 ${scrollPosition > 400 ? 'scroll navbar-navOnthorPAge shadow-sm ' : location.pathname === '/Branding' ? 'bg-light shadow-sm ' : ''}`}
                // variant="dark" 
                >
                    <Container fluid>
                        <Navbar.Brand className='Logo__RightClick' as={Link} to={'/'}>
                            <img src={img.LogoMainColor} width={"150px"} height={'45px'} alt="" />
                        </Navbar.Brand>
                        <div className='app__menu-left'>
                            <Link to={'/contactus'}>
                                <motion.div
                                    className='app__menu-talk'
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.8 }} >
                                    {isLang === "en" ? 'let’s Talk' : '  اسألنا الحين!'}
                                    <span className="spinner-grow spinner-grow-sm" role="status"    ></span>

                                </motion.div>
                            </Link>

                            <Icons.Menu className='icons__menu' onClick={toggleMenu} />
                        </div>
                        {
                            menuOpen && (
                                <motion.div
                                    className={`${isLang === "en" ? 'app__menu' : '  app__menu-rtl'} shadow-lg ${menuOpen ? 'show' : ''}`}
                                    initial="hidden"
                                    animate={menuOpen ? 'visible' : 'hidden'}
                                    exit="exit"
                                    variants={menuVariants}
                                >
                                    <div className='app__closeIcons'>
                                        <Icons.Menu className='icons__Close' onClick={toggleMenu} />
                                    </div>
                                    <div className="   d-flex flex-column"  >
                                        <section className={'routes'}>
                                            {routes?.map((root, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Link
                                                            to={root.path}
                                                            className={`link ${activeLink === i ? 'active' : ''}`}
                                                            onClick={() => handleLinkClick(i)}
                                                        >
                                                                <div className="line">
                                                                <Icons.Line className="logoSvg" style={{ width: 20 }} />
                                                            </div> 
                                                            <div className={`icon  ${activeLink === i ? 'active' : ''}`} id={root.name} data-tooltip-content={root.nameEn}>
                                                                {root.icon}
                                                            </div>
                                                            <div className="link_text">{isLang === "en" ? root.nameEn : root.nameAr}</div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </section>
                                    </div>

                                </motion.div>

                            )
                        }
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default NavBar
