import AOS from 'aos';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import AboutServices from '../../../Services/AboutServices';
import LocalizationData from '../../../Translate/LocalizationData';
import Component from '../../../constants/Component';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';

const Section2 = () => {

    let { isLang } = useContext(LocalizationContext);
    const [aboutData, setAboutData] = useState([])
    const [aboutData2, setAboutData2] = useState([])
    const [loading, setloading] = useState(false)



    useEffect(() => {
        AOS.init();
        AOS.refresh();

        
        setloading(false)
        AboutServices(isLang, 2).then(({ data }) => {
            if (data?.status === 200) {
                setloading(true)
                setAboutData(data?.data)
                setAboutData2(data?.data?.about_data)
            }
        }).catch((error) => {
        });



    }, [isLang])

    return (
        <>

            {
                aboutData &&
                <div className=' about__Container   bg_light1'  >
                    {
                        loading ?
                            <Container className='app__about '>
                                <div className="content"  >
                                    <Component.Title
                                        duration_p={1.5}
                                        width={'w-100'}
                                        title={aboutData?.section_title}
                                        text={aboutData?.section_content}
                                        textStyle={'text-center'}
                                    />
                                </div>
                                <div className="summary mb-5 d-flex gap-5">
                                    <div className="project" data-aos="fade-left" data-aos-duration="1500" data-aos-easing="ease-out-cubic"  >
                                        <span className="title">
                                            {LocalizationData[isLang]?.About.summaryProject}
                                        </span>
                                        <span className="score mt-2" dir='ltr'>
                                            {'+'}<CountUp start={0} end={aboutData?.projects || 0} duration={5} delay={0.5} />
                                        </span>
                                    </div>
                                    <div className="project" data-aos="flip-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                        <span className="title"> {LocalizationData[isLang]?.About.summaryClients} </span>
                                        <span className="score mt-2" dir='ltr' >
                                            {'+'}<CountUp start={0} end={aboutData?.clients || 0} duration={7} delay={1.5} />
                                        </span>
                                    </div>
                                    <div className="project" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                        <span className="title"  >
                                            {LocalizationData[isLang]?.About.summaryExperience}
                                        </span>
                                        <span className="score mt-2" dir='ltr' >
                                            {'+'}<CountUp start={0} end={aboutData?.experience || 0} duration={7} delay={1.5} />
                                            <span className="years mt-3">
                                                {LocalizationData[isLang]?.About.summaryYear}
                                            </span>
                                        </span>

                                    </div>
                                </div>
                                <div className="app__Who_we_Are ">
                                    <Row>
                                        <Col xl={6} lg={6} md={12} sm={12} className="m-0 p-0 ">
                                            <div className='about_img1' data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                                <img loading='lazy' src={aboutData2[0]?.image} alt="item-feature" className='w-100' height={'500px'} />
                                            </div>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12} className=" content  p-0 m-0" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                            <div className="content d-flex flex-column">
                                                <span> {aboutData2[0]?.title}</span>
                                                <p>{aboutData2[0]?.description}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className=' about_block '>
                                        <Col xl={6} lg={6} md={12} sm={12} className="m-0 p-0 ">
                                            <div data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                                <img loading='lazy' src={aboutData2[1]?.image} alt="item-feature" className='w-100' height={'500px'} />
                                            </div>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12} className=" content p-0 m-0">

                                            <div className="content d-flex flex-column">
                                                <span> {aboutData2[1]?.title}</span>
                                                <p>{aboutData2[1]?.description}</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='seconed-conteent about_none'>
                                        <Col xl={6} lg={6} md={12} sm={12} className=" content p-0 m-0" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-out-cubic">

                                            <div className="content d-flex flex-column">
                                                <span> {aboutData2[1]?.title}</span>
                                                <p>{aboutData2[1]?.description}</p>
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} className="m-0 p-0 ">
                                            <div className='about_img2' data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                                                <img
                                                    src={aboutData2[1]?.image}
                                                    width={'100%'}
                                                    height={'500px'}
                                                    alt="img about"
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <div className="pt-4">
                                    <Component.BtnSeeMore text={LocalizationData[isLang]?.btnMore} fontSize={'16px'} path={isLang == "en" ? '/about-us' : '/ar/نبذة-عنا'} justifyContent="center" widthBtn={'150px'} />


                                </div>

                            </Container> : ''
                    }
                </div>
            }
        </>
    )
}

export default Section2