import React from 'react'

const SeoAbout = ({    data = [], isLang }) => {
    return (
        <div className='about_container' dir={isLang == "en" ? 'ltr' : 'rtl'} >
            <div className="app_seo_about ">
                <h3>{data[0]?.title}</h3>
                <p>{data[0]?.description}</p>
            </div>
            <div className="about_seo">
                <div className="  mt-5">
                    <section className='about_seo_section '>
                        <div className="section">
                            <h2>{data[1]?.title}</h2>
                            <p>{data[1]?.description}</p>
                        </div>

                        <div className="section">
                            <h2>{data[2]?.title}</h2>
                            <p>{data[2]?.description}</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default SeoAbout
