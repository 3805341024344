import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import Icons from "../../constants/Icons";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameAr: "  الرئيسه",
        icon: <Icons.Home className="logoSvg" style={{ width: 20 }} />,
        icon2: <HomeOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />
    },
    {
        path: "/about-us",
        nameEn: "about Us",
        nameAr: "من نحــن ",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />, 
        icon2: <InfoOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },

    {
        path: "/projects",
        nameEn: "Portfolio",
        nameAr: "أعمالنا",
        icon: <Icons.Projects className="logoSvg" style={{ width: 20 }} />,
        icon2: <AccountTreeOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },
    {
        path: "/service",
        nameEn: "Service",
        nameAr: "خدماتنا ",
        icon: <Icons.Service className="logoSvg" style={{ width: 20 }} />,
        icon2: <MiscellaneousServicesOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />,
        subRoutes: [
            {
                path: "/service/Digital-Marketing",
                nameEn: "Digital marketing",
                nameAr: "التسويق الرقمي "
            },
            {
                path: "/service/Web-Development",
                nameEn: "Website development",
                nameAr: "تطوير المواقع"
            },
            {
                path: "/service/Internal-Communication-And-Events",
                nameEn: " Events",
                nameAr: "الاتصالات الداخلية والأحداث"
            },
            {
                path: "/service/Visual-Production",
                nameEn: "Visual Production",
                nameAr: "الإنتاج المرئي"
            },
            {
                path: "/service/Socia-Media-Management",
                nameEn: "Socia Media Management",
                nameAr: "إدارة وسائل التواصل الاجتماعي"
            },
            {
                path: "/service/Branding",
                nameEn: "Branding",
                nameAr: "العلامة التجارية"
            },
        ]
    },
    // {
    //     path: "/jobs",
    //     nameEn: "jobs",
    //     icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />,
    // },

    {
        path: "/clients",
        nameEn: "our client",
        nameAr: "عملائنا",
        icon: <Icons.Clients className="logoSvg" style={{ width: 20 }} />,
        icon2: <GroupOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },

    {
        path: "/blogs",
        nameEn: "Blogs",
        nameAr: "مقالات",
        icon: <Icons.Blogs className="logoSvg" style={{ width: 20 }} />,
        icon2: <BookOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },
    {
        path: "/jobs",
        nameEn: "Jobs",
        nameAr: "الوظائف ",
        icon: <Icons.Jops className="logoSvg" style={{ width: 20 }} />,
        icon2: <BusinessCenterOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },
    {
        path: "/contactus",
        nameEn: "Contact Us",
        nameAr: "أتصل بنا ",
        icon: <Icons.ContactUs className="logoSvg" style={{ width: 20 }} />,
        icon2: <CallOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />

    },

];

export default routes