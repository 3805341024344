import AOS from 'aos'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import ClientsService from '../../../Services/ClientsService'
import LocalizationData from '../../../Translate/LocalizationData'
import Component from '../../../constants/Component'
import { LocalizationContext } from '../../../context/LangChange'
import './style.scss'

const Section5 = () => {
  let { isLang } = useContext(LocalizationContext);
  const [data, setData] = useState([])
  const [loading, setloading] = useState(false)

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1500,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };


  useEffect(() => {
    AOS.init();
    AOS.refresh();
 
    
      const timeOut = setTimeout(() => {
        setloading(false)
        ClientsService(isLang).then(({ data }) => {
          if (data?.status === 200) {
            setData(data?.data)
            setloading(true)
          }
        }).catch((error) => {
        });
      }, 200);
      return () => clearTimeout(timeOut);
 
      

  }, [isLang])

  return (
    <>
      {
        loading &&
        <div className='app__clients '>
          <Container>
            <Row>
              <Col xl={5} lg={5} md={6} sm={12}>
                <div className="app__clients-left">
                  <Component.Title
                    duration_p={1.5}
                    title={data?.section_title}
                    text={data?.section_content}
                  />
                  <div className="app__clients-btn">
                    <Component.BtnSeeMore text={LocalizationData[isLang]?.btnMore} path={isLang=="en"?'/clients':'/ar/عملائنا'} flexDirection="column" justifyContent="flex-end" widthBtn={'fit-content'} />
                  </div>
                </div>
              </Col>
              <Col xl={7} lg={7} md={6} sm={12} className='my-5 py-4'>
                <div data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-easing="ease-out-cubic">
                  <Slider {...settings} className='slider__feedback  '>

                    {
                      data?.clients?.map((item, index) => (
                        <div key={index} className='slider__item  ' >
                          <img width={150} height={90} className='shadow-lg' src={item?.image} alt="company" style={{ cursor: 'pointer', objectFit: 'contain', objectPosition: '50% 50%' }} />

                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  )
}

export default Section5
