
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import img from '../../assets/Img';
import './seo.scss';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // your primary color
        },
        secondary: {
            main: '#FA6409', // your new secondary color
        },
    },
});

const Stage = ({ isLang }) => {
    const [value, setValue] = useState(0);
    const [data, setData] = useState(null); // تعيين الحالة الأولية للبيانات

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // TODO createBrowserRouter
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);

    let url = `${process.env.REACT_APP_API_URL}/stages?type=0`
    if (pathname === '/en/services/branding' || pathname === '/ar/خدماتنا/الهوية') {
        url = `${process.env.REACT_APP_API_URL}/stages?type=0`;
    } else if (pathname === '/en/services/website-development' || pathname === '/ar/خدماتنا/تطوير-الموقع') {
        url = `${process.env.REACT_APP_API_URL}/stages?type=1`;
    } else if (pathname === '/en/services/social-media-marketing' || pathname === '/ar/خدماتنا/تسويق-وسائل-الاعلام-الاجتماعية') {
        url = `${process.env.REACT_APP_API_URL}/stages?type=2`;
    } 
 
    useEffect(() => {
        axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        }).then(response => {
            setData(response.data.data); 
        }).catch(error => { 
        });
    }, [isLang]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setCurrentIndex(selectedIndex);
        setValue(0);
    };

    return (
        <div className='mt-3' dir={isLang == "en" ? 'ltr' : 'rtl'}>
            <h2 className='text-center'>{data?.[currentIndex]?.title || 'Loading...'}</h2>
            <img src={img.seoBG} className='w-100 seoBG' alt="" srcset="" />
            <div className="STAGE_PALN">
                <div className="contect h-100">
                    <Container className='h-100'>
                        <Carousel className="app__slider-home h-100" interval={3000} showArrows={true} activeIndex={currentIndex} onSelect={handleSelect} prevIcon={null} nextIcon={null}>
                            {data?.map((stage) => (
                                <Carousel.Item className="w-100 h-100" key={stage.id}>
                                    <Row className="w-100 h-100" >
                                        {/* <span>Stage {index + 1}</span> */}
                                        <Col xl={8} lg={8} md={12} sm={12} >
                                            {/* ... محتوى Carousel ... */}

                                            {stage.details.map((detail, detailIndex) => (
                                                value === detailIndex && (
                                                    <div key={detail.id}>
                                                        <h1>{detail.title}</h1>
                                                        <p>{detail.description}</p>
                                                    </div>
                                                ))
                                            )}
                                            <ThemeProvider theme={theme}>
                                                <Box sx={{ width: '100%', marginTop: '100px', display: 'flex', justifyContent: '' }}>
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        textColor="inherit"
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        allowScrollButtonsMobile
                                                        indicatorColor="secondary"
                                                        aria-label="scrollable auto tabs example"
                                                        sx={{
                                                            '& .MuiTab-root': {
                                                                backgroundColor: '#2D3748', // لون الخلفية للتبويبات غير المحددة
                                                                color: '#CBD5E0', // لون النص للتبويبات غير المحددة
                                                                textTransform: 'lowercase',
                                                                justifyContent: 'center',
                                                                fontSize: '13px',
                                                                borderRadius: '6px'
                                                            },
                                                            '& .Mui-selected': {
                                                                backgroundColor: '#FA6409', // أحمر للتبويب المحدد
                                                                color: '#FFFFFF', // نص أبيض للتبويب المحدد
                                                            },
                                                            display: 'flex',
                                                            gap: '10px'
                                                        }}
                                                    >
                                                        {stage.details.map((detail, detailIndex) => (
                                                            <Tab key={detail.id} value={detailIndex} label={detail.title} />
                                                        ))}
                                                    </Tabs>
                                                </Box>
                                            </ThemeProvider>
                                        </Col>
                                        <Col xl={4} lg={4} md={12} sm={12}>
                                            {/* ... صورة Carousel ... */}
                                            <div className="w-100 h-100" >
                                                {stage.details.map((detail, detailIndex) => (
                                                    value === detailIndex && (
                                                        <img key={detail.id} src={detail.image} alt={detail.title} className='w-75 stage_image' />
                                                    ))
                                                )}

                                            </div>

                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Stage
