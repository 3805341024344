import React from 'react'

const Facebook = ({ color }) => {
    return (
        <>
            <svg width="24" height="25" viewBox="0 0 16 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1049 4.81404H16V0.203043C14.5983 0.0657965 13.1898 -0.00196371 11.7806 4.33029e-05C7.59192 4.33029e-05 4.72762 2.40704 4.72762 6.81503V10.614H0V15.776H4.72762V29H10.3946V15.776H15.1068L15.8152 10.614H10.3946V7.32253C10.3946 5.80003 10.8258 4.81404 13.1049 4.81404Z" fill={`${color}`} />
            </svg>
        </>
    )
}

export default Facebook