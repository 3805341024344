import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const LoaderBots = () => {
    return (
        <div className='d-flex justify-content-center align-item-center'>
            <Player
                className='w-100'
                autoplay
                loop
                src={'https://lottie.host/69ccc761-b635-4a30-ae1e-501f26e34962/rq7s0FyKSS.json'}
            />
        </div>
    )
}

export default LoaderBots
