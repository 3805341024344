import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import HomeServices from '../../Services/HomeServices';
import LocalizationData from '../../Translate/LocalizationData';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import './Home.scss';

const Home = () => {
    const [sliderData, setSliderData] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    let { isLang } = useContext(LocalizationContext);
    useEffect(() => {
 
        window.scroll(0, 0)
        HomeServices(isLang).then(({ data }) => {
            setIsLoading(false)
            if (data?.status === 200) {
                setSliderData(data?.data)
                setIsLoading(true)
            }
        }).catch((error) => {

        });
    }, [isLang]);




    return (

        <div dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{isLang === "en" ? 'Right Click - Digital Marketing Agency in Saudi Arabia' : 'رايت كليك - وكالة التسويق الرقمي في السعودية'}</title>
                <meta name="description" content={isLang === "en" ? 'Right Click is a leading digital marketing agency in Saudi Arabia. We provide top-notch services in SEO, social media marketing, content creation, and more.' : 'رايت كليك هي وكالة تسويق رقمي رائدة في السعودية. نقدم خدمات متميزة في تحسين محركات البحث، التسويق عبر وسائل التواصل الاجتماعي، إنشاء المحتوى والمزيد.'} />
                <meta name="keywords" content={isLang === "en" ? 'Digital Marketing, SEO, Social Media Marketing, Content Creation, Saudi Arabia' : 'التسويق الرقمي، تحسين محركات البحث، التسويق عبر وسائل التواصل الاجتماعي، إنشاء المحتوى، السعودية'} />
                <meta name="author" content="Right Click" />
                <meta property="og:title" content={isLang === "en" ? 'Right Click - Digital Marketing Agency in Saudi Arabia' : 'رايت كليك - وكالة التسويق الرقمي في السعودية'} />
                <meta property="og:description" content={isLang === "en" ? 'Right Click is a leading digital marketing agency in Saudi Arabia. We provide top-notch services in SEO, social media marketing, content creation, and more.' : 'رايت كليك هي وكالة تسويق رقمي رائدة في السعودية. نقدم خدمات متميزة في تحسين محركات البحث، التسويق عبر وسائل التواصل الاجتماعي، إنشاء المحتوى والمزيد.'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
                {sliderData.map((item, index) => (
                    <meta key={index} property="og:image" content={item?.image} />
                ))}
            </Helmet>
            {
                !isLoading ? <Component.LoadingPage /> :
                    <>
                        {
                            sliderData && (
                                <div >
                                    <Carousel className="app__slider-home" interval={3000} fade showArrows={false} prevIcon={null} nextIcon={null}>
                                        {sliderData.map((item, index) => (
                                            <Carousel.Item key={index} className="   w-100 ">
                                                <img src={item?.image} alt="Slider 2" className="slider__home  w-100" />
                                                <Carousel.Caption className={`${isLang === "en" ? 'content__silder  ' : 'content__silder content__silder-rtl'}  `}>
                                                    <div className="content">
                                                        <span>{item.title}</span>
                                                        <p>{item.description}</p>
                                                        <Link to={isLang === "en" ? `/serviceContact/${item?.image.replace('https://new.rightclick.com.sa/public/images/slider/', '')}/${index + 1}` : `/ar/الاتصال-بالخدمة/${item?.image.replace('https://new.rightclick.com.sa/public/images/slider/', '')}/${index + 1}`} className='link__home'>
                                                            <button className="button-86" variant="primary">{LocalizationData[isLang]?.slider.btn}</button>
                                                        </Link>
                                                    </div>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                            )
                        }
                        <>
                            <Component.Section2 />
                            <Component.Section3 />
                            <Component.WhatWeDo />
                            <Component.Section5 />
                            <Component.BlogsHome />
                            <Component.ContactUSHome />
                            <Component.Footer />
                        </>
                    </>
            }
        </div >
    );
}

export default Home