import React from 'react'
import Icons from '../../constants/Icons'
import { motion } from 'framer-motion';

const Calling = ({ classStyle }) => {

    return (
        <div className={` group_Calling ${classStyle}`} >

            <a href="tel:+966" target='_blank' rel="noreferrer">
                <motion.div
                    className="phoneIcons round d-flex justify-content-center align-items-center "
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.3 }}
                >
                    <Icons.Phone />
                </motion.div>
            </a>

            <a href="https://wa.me/+966" target='_blank' rel="noreferrer">
                <motion.div
                    className="phoneIcons round d-flex justify-content-center align-items-center "
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.3 }}
                >
                    <Icons.Whatsapp />
                </motion.div>
            </a>

        </div>
    )
}

export default Calling