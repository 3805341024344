import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { ProjectDetailsServices } from '../../../../Services/ProjectServices'
import Component from '../../../../constants/Component'
import Icons from '../../../../constants/Icons'
import { LocalizationContext } from '../../../../context/LangChange'
import img from '../../../../assets/Img'
import { Helmet } from 'react-helmet-async'


const ProjectDetails = () => {
    const [Index, setIndex] = useState(1);
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    const [data, setData] = useState([])
    const [scopes, setScopes] = useState([])
    const [images, setImages] = useState([])
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setisLoading(false)
            ProjectDetailsServices(isLang, id).then(({ data }) => {
                if (data?.status === 200) {
                    setData(data?.data)
                    setScopes(data?.data?.scopes)
                    setImages(data?.data?.images)
                    setisLoading(true)
                }
            }).catch((error) => {
            });
        }, 100);
        return () => clearTimeout(timeOut);
    }, [isLang, id])

    const pageTitle = pathname
        ?.replace('/ar/', '')
        ?.replace('/projects', 'Projects')
        ?.replace('/', ' | ')
        ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
        ?.replace(/-/g, ' '); // Replace all hyphens with spaces

    return (
        
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            {
                isLoading ?
                    <div className='overflow-hidden app__project_details-title '>
                        <Component.HeaderPages
                            img={img.HeaderAboutUS}
                            title={data?.title}
                        />
                        <Row className='app__project_details' dir={isLang === "en" ? 'lrt' : 'rtl'}>
                            <Col xl={6} lg={6} md={6} sm={12} >
                                <div className="app__title-details">
                                    <div className="body">
                                        <Container>
                                            <h6>{data?.title}</h6>
                                            <span className='duration_date_description '>{data?.description}</span>
                                            <hr className='mt-4' />
                                            {/* <h6 className='duration'> {isLang === "en" ? 'Duration' : 'مـــدة'} : <span className='duration_date'>{data?.duration}</span></h6> */}
                                        </Container>
                                    </div>
                                </div>
                                <div className="scope " dir="ltr">
                                    <h1 className='text-center mt-3'> {isLang === "en" ? 'scopes' : 'النطاقات'}</h1>
                                    <VerticalTimeline layout={`${isLang === "en" ? '1-column-left' : '1-column-right'}`} >
                                        {
                                            scopes?.map((item, index) => (
                                                <VerticalTimelineElement
                                                    key={index}
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{ background: '#24282C', color: '#fff' }}
                                                    contentArrowStyle={{ borderRight: '7px solid  #24282C' }}
                                                    // date="2011 - present"
                                                    iconStyle={{ background: '#24282C', color: '#fff' }}
                                                    icon={<Icons.Blogs />}
                                                    lineClassName="vertical-timeline-line-black"


                                                >
                                                    <h3 dir={isLang === "en" ? 'lrt' : 'rtl'} className="vertical-timeline-element-title">{isLang === "en" ? item?.scope_en : item?.scope_ar}</h3>

                                                </VerticalTimelineElement>
                                            ))
                                        }
                                    </VerticalTimeline>
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} className='app__content'>
                                <div className="body">

                                    <div className='card__image mb-5'>
                                        {
                                            images &&
                                            <>
                                                <div className="product-detail-image p-5"   >
                                                    {images?.length === 1 ?
                                                        <img
                                                            src={images[0]?.image}
                                                            alt='product detail'
                                                            style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                                                        /> :
                                                        <img
                                                            src={images && images[Index]?.image}
                                                            alt='product detail'
                                                            style={{ borderRadius: '15px', objectFit: 'cover', objectPosition: '50% 50%' }}
                                                        />
                                                    }
                                                </div>

                                                <div className="small-images-container">
                                                    {images?.slice(0, 3).map((item, i) => (
                                                        item && item.image ? (
                                                            <img
                                                                className={i === Index ? 'small-image selected-image' : 'small-image'}
                                                                key={i}
                                                                src={item.image}
                                                                onMouseEnter={() => setIndex(i)}
                                                                style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
                                                                alt='image'
                                                            />

                                                        ) : null
                                                    ))}

                                                    {images?.length > 2 && (
                                                        <div className="image-container" dir={isLang === 'en' ? 'ltr' : 'rtl'} onClick={handleClickOpen}>
                                                            {images[3]?.image && (
                                                                <img
                                                                    className="small-image"
                                                                    src={images[3]?.image}
                                                                    width="100%"
                                                                    height="300px"
                                                                    alt="img about"
                                                                />
                                                            )}
                                                            <div className="overlay_project overlay_small-image">
                                                                <h1>+{images?.length - 3}</h1>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className="mt-5">
                                        {data?.link &&
                                            <Box sx={{ maxWidth: '100%', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Box width="100%" height={0} paddingBottom="56.25%" position="relative">
                                                    {data?.link != "#" &&
                                                        <iframe
                                                            title="YouTube Video"
                                                            className='pt-2 pb-2'
                                                            width="100%"
                                                            height="100%"
                                                            src={`https://www.youtube.com/embed/${data.link.replace('https://youtu.be/', '')}?autoplay=0&vq=hd720`}
                                                            frameBorder="0"
                                                            allow="autoplay; 0"
                                                            allowFullScreen
                                                            style={{ position: 'absolute', top: 0, left: 0 }}
                                                        />
                                                    }

                                                </Box>
                                            </Box>}
                                    </div>

                                </div>
                            </Col>


                        </Row>
                        <Component.ModalProjectsDetails open={open} projectsData={images} isLang={isLang} handleClose={handleClose} />
                        <Component.Footer />
                    </div> : <Component.LoadingPage />
            }
        </>
    )
}

export default ProjectDetails
