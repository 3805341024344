import React, { createContext, useEffect, useState } from 'react';
export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(localStorage.getItem('langChange'));
  const [loading, setloading] = useState(true);

  

  useEffect(() => {

    setInterval(() => {
      setloading(false)
    }, 3000);
    if (!localStorage.getItem('langChange')) {
      setIsLang('en')
      return localStorage.setItem('langChange', 'en')
    } else {
      return localStorage.setItem('langChange', isLang)
    }
  }, [isLang])

  return (
    <LocalizationContext.Provider value={{ setloading, loading, isLang, setIsLang }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange