import React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import { Container, Row } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './style.scss';
const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000000ac', // يمكنك تعيين لون الخلفية الذي ترغبه هنا
        },
      },
    },
  },
});
const ModalProjects = ({ open, projectsData, isLang, handleClose }) => {
  const images = projectsData.map((project) => ({
    original: project.image,
    thumbnail: project.image,
    originalAlt: project.title,
    thumbnailAlt: project.title,
    description: project.title,
    key_word: project.key_word,
    key_word_ar: project.key_word_ar,
    id: project.id,
  }));

  const renderItem = (item) => { 
    return (
      <div className="projects">
        <div className="custom-image-gallery-slide">
          <Link to={isLang === "en" ? `/projects/${item.key_word}` : `/ar/مشاريعنا/${item.key_word_ar}`}>
            <img
              src={item.original}
              alt={item.originalAlt}
              className="custom-image-gallery-image object__fit__contain"
            />
          </Link>
          {item.description && (
            <div className={`${isLang === "en" ? 'image-gallery-description' : '  image-gallery-descriptionAr'}`} >
              {item.description}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        dir={isLang === 'en' ? 'ltr' : 'rtl'}
        open={open}
        maxWidth="xxl"
        onClose={handleClose}
        BackdropClick={true}
      >
        <List className="modal__image">
          <Container fluid>
            <Row className="mb-3 d-flex justify-content-center align-items-center">
              <ReactImageGallery
                lazyLoad={true}
                items={images}
                infinite={true}
                showBullets={true}
                autoPlay={true}
                thumbnailPosition={'bottom'}
                additionalClass="custom-image-gallery"
                renderItem={renderItem}
              />
            </Row>
          </Container>
        </List>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalProjects;
