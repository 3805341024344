import Dialog from '@mui/material/Dialog';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './clients.scss';

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: 'rgba(255, 255, 255, 0.641)', // يمكنك تعيين لون الخلفية الذي ترغبه هنا
                },
            },
        },
    },
});
const ModalClients = ({ open, projectsData, isLang, handleClose, initialImageIndex }) => {
    const images = projectsData.map((project) => ({
        original: project.image,
        thumbnail: project.image,
        originalAlt: project.title,
        thumbnailAlt: project.title,
        description: project.title,
        id: project.id,
    }));

    const renderCustomThumbInner = (item) => {
        return (


            <div className="image-gallery-thumbnail-inner " >
                <img
                    src={item.thumbnail}
                    alt={item.thumbnailAlt}
                    className="image-gallery-thumbnail-image"
                />
            </div>
        );
    };

    const renderItem = (item) => {
        return (
            <div className="clients-img" style={{ marginTop: '150px' }}>
                <div className="custom-image-gallery-slide">
                    <div>
                        <img
                            src={item.original}
                            alt={item.originalAlt}
                            className="custom-image-gallery-image"
                        />
                    </div>
                </div>
            </div>
        );
    }; 
    return (
        <ThemeProvider theme={theme}>

            <Dialog
                maxWidth="xxl"
                dir={isLang === 'en' ? 'ltr' : 'rtl'}
                open={open}
                onClose={handleClose}
                BackdropClick={true}
            >
                <div className="modal__image-clients">
                    <Container fluid>
                        <Row className="mb-3 d-flex justify-content-center align-items-center">
                            <ReactImageGallery
                                items={images}
                                infinite={true}
                                autoPlay={true}
                                showIndex={true}
                                startIndex={initialImageIndex}
                                thumbnailPosition={'bottom'}
                                additionalClass="custom-image-gallery"
                                renderItem={renderItem}
                                renderThumbInner={renderCustomThumbInner} // استدعاء الدالة الجديدة للـ thumbnail
                                showFullscreenButton={true} // يمكنك تعطيل أزرار التكبير والتصغير إذا أردت.
                                showPlayButton={true} // يمكنك تعطيل زر التشغيل التلقائي إذا أردت.
                                itemsPerRow={1} // تعيين عدد الصور المراد عرضها لكل thumbnail
                            />
                        </Row>
                    </Container>
                </div>
            </Dialog>
        </ThemeProvider>
    );
};

export default ModalClients;
