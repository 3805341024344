import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const ErrorPage = () => {
    return (
        <div>
            <div className="app__error">
                <Player
                    // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                    src='https://assets9.lottiefiles.com/packages/lf20_aaesnvcw.json'
                    autoplay
                    loop
                    className='error_payer'
                />
            </div>
        </div>
    )
}

export default ErrorPage
