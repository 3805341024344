import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'
import { BlogsServices } from '../../../Services/BlogsServices'
import LocalizationData from '../../../Translate/LocalizationData'
import img from '../../../assets/Img'
import Component from '../../../constants/Component'
import { LocalizationContext } from '../../../context/LangChange'
import './style.scss'

const BlogsHome = () => {
    let { isLang } = useContext(LocalizationContext)
    const bgStyle = {
        backgroundImage: `url(${img.BlogBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%'
    };
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 2000,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [dataBlog, setDataBlogs] = useState([])
    const [data, setData] = useState(null)
    const [loading, setloading] = useState(false)



    useEffect(() => {
     
        
            const timeOut = setTimeout(() => {
                setloading(false)
                BlogsServices(isLang).then(({ data }) => {
                    if (data?.status === 200) {
                        setloading(true)
                        setDataBlogs(data?.data?.clients)
                        setData(data?.data)
                    }
                }).catch((error) => {
                });
            }, 200);
            return () => clearTimeout(timeOut);
      
            
    }, [isLang])

    return (
        <>
            {
                loading ?
                    <div className="app__blogs-home  overflow-hidden" style={bgStyle}>
                        <Container className='mt-5 '>
                            <Component.Title
                                duration_p={1.5}
                                title={data?.section_title}
                                textStyle={'text-center'}
                                text={data?.section_content}
                            />

                            <Slider {...settings} className='slider__feedback d-flex gap-5  sliders-blogs mt-4'>

                                {
                                    dataBlog?.map((items, index) => (
                                        <div key={index} className='slider__item ' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                            <img
                                                src={items.image}
                                                width={'100%'}
                                                height={'200px'}
                                                alt="img about"
                                                style={{ borderRadius: '5px', overflow: 'hidden' }}
                                            />
                                            <div  >
                                                <div className="content shadow-sm">
                                                    <div className="header">
                                                        <h5>
                                                            {items?.short_title?.split(" ")?.slice(0, 2)?.join(" ")}
                                                        </h5>
                                                    </div>
                                                    <div className="body">
                                                        <p>{items?.short_description?.split(" ")?.slice(0, 10)?.join(" ")}</p>

                                                    </div>

                                                    <Component.BtnSeeMore fontSize={'10px'} text={LocalizationData[isLang]?.btnDetails} padding={'2px 10px'} delay={0}
                                                     path={isLang == "en" ? `/blogs/${items.key_word}` : `/ar/المدونات/${items.key_word_ar}`} flexDirection="column" justifyContent="flex-end" widthBtn={'90px'} />

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </Slider>


                            <div className="app__clients-btn">
                                <Component.BtnSeeMore text={LocalizationData[isLang]?.btnMore} fontSize={'16px'} path={isLang==="en"?'/blogs':'/ar/المدونات'} justifyContent="center" widthBtn={'150px'} />
                            </div>

                        </Container>
                    </div> :
                    <Component.LoaderBots />
            }
        </>
    )
}

export default BlogsHome
