import { Alert, Box, Button } from '@mui/material';
import AOS from 'aos';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { ContantServices } from '../../../Services/ContantServices';
import LocalizationData from '../../../Translate/LocalizationData';
import Component from '../../../constants/Component';
import { LocalizationContext } from '../../../context/LangChange';

const ContactUSHome = () => {
    let { isLang } = useContext(LocalizationContext);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? 'you name is required' : 'يرجى إدخال الاسم'),
        email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
        phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
        reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
        message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
    });
    const [meassageApi, setMeassageApi] = useState(null)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            reason: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
   
            await ContantServices(isLang, values).then(({ data }) => {
    
                setMeassageApi(data?.message)
                resetForm();
            })
        }
    });

    return (
        <>
            <div className='app__contact bg-light mt-5' id='contactUS'>
                <Container>


                    <div className="mt-5">
                        <Component.Title duration_p={1.5}
                            title={LocalizationData[isLang].ContactUs.title}
                            textStyle={'text-left  '}
                        />
                    </div>
                    <div className="mt-5 row">

                        <Col xl={7} lg={7} md={12} sm={12} data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-out-cubic" className=' app__contact-content' >
                            <div className="container-content">
                                {
                                    meassageApi === null ?
                                        <Form className="form" onSubmit={formik.handleSubmit}>
                                            <Row dir={isLang=="en"?'ltr':'rtl'}>
                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label1}  </Form.Label>
                                                        <Form.Control
                                                            name="name"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.name && formik.errors.name}
                                                            className='input-style' type="text" placeholder={LocalizationData[isLang].ContactUs.Placeholder1} />
                                                        {formik.touched.name && formik.errors.name && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.name}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                        <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label2}  </Form.Label>
                                                        <Form.Control
                                                            name="email"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.email && formik.errors.email}
                                                            className='input-style' type="email" placeholder={LocalizationData[isLang].ContactUs.Placeholder2} />
                                                        {formik.touched.email && formik.errors.email && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                                        <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label3}</Form.Label>
                                                        <Form.Control
                                                            name="phone"
                                                            value={formik.values.phone}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.phone && formik.errors.phone}
                                                            className='input-style' type="tel" placeholder="996+" />
                                                        {formik.touched.phone && formik.errors.phone && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.phone}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={6} lg={6} md={6} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                                        <Form.Label className='label-style'> {LocalizationData[isLang].ContactUs.label4}</Form.Label>
                                                        <Form.Control
                                                            name="reason"
                                                            value={formik.values.reason}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.reason && formik.errors.reason}
                                                            className='input-style' type="text" placeholder={LocalizationData[isLang].ContactUs.Placeholder4} />
                                                        {formik.touched.reason && formik.errors.reason && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.reason}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col xl={12} lg={12} md={12} sm={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                                                        <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label5}</Form.Label>
                                                        <Form.Control
                                                            name="message"
                                                            value={formik.values.message}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={formik.touched.message && formik.errors.message}
                                                            className='input-style' as="textarea" rows={5} placeholder={LocalizationData[isLang].ContactUs.Placeholder5} />
                                                        {formik.touched.message && formik.errors.message && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.message}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'center'} >
                                                    <Button
                                                        type="submit"
                                                        className='btn__overlay'
                                                        variant="contained"
                                                        color="warning"
                                                        sx={{
                                                            marginBottom: '20px',
                                                            textTransform: 'capitalize',
                                                            backgroundColor: '#FA6409',
                                                            color: '#24282C',
                                                            '&:hover': {
                                                                backgroundColor: '#000000',
                                                                color: '#ffffff',
                                                            },
                                                            fontSize: `16px !important`
                                                        }}
                                                    >
                                                        {LocalizationData[isLang].ContactUs.btnSend}
                                                    </Button>
                                                </Box>

                                            </Row>
                                        </Form> :
                                        <>

                                            <Alert variant='standard'>{meassageApi}</Alert>
                                            <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'center'} >
                                                <Button
                                                    type="submit"
                                                    className='btn__overlay'
                                                    variant="contained"
                                                    color="warning"
                                                    sx={{
                                                        marginBottom: '20px',
                                                        textTransform: 'capitalize',
                                                        backgroundColor: '#FA6409',
                                                        color: '#24282C',
                                                        '&:hover': {
                                                            backgroundColor: '#000000',
                                                            color: '#ffffff',
                                                        },
                                                        fontSize: `16px !important`
                                                    }}
                                                    onClick={() => setMeassageApi(null)}
                                                >
                                                    {isLang === "en" ? 'Do you want send anthor messange' : 'هل تريد ارسال طلب اخري'}
                                                </Button>
                                            </Box>
                                        </>
                                }
                            </div>
                        </Col>

                        <Col xl={5} lg={5} md={false} data-aos="fade-left" data-aos-duration="2000" data-aos-easing="ease-out-cubic" sm={false}>
                            <Container className='map-contactus '  >
                                <div className="mapouter ">
                                    <div className="gmap_canvas shadow">
                                        {/*                 <iframe
                                            className="gmap_iframe"
                                            width="100%"
                                            height="100%"
                                            title="خريطة الموقع"
                                            src="https://maps.google.com/maps?q=Right Click, Al Mohammadiyyah, Riyadh 12363, Saudi Arabia&t=&z=14&ie=UTF8&iwloc=&output=embed"
                                        /> */}

                                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.6545363549903!2d46.64433657536694!3d24.738737778008943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzE5LjUiTiA0NsKwMzgnNDguOSJF!5e0!3m2!1sen!2ssa!4v1692806342120!5m2!1sen!2ssa" width="100%"
                                            height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </div>

                </Container>

            </div >
        </>
    )
}

export default ContactUSHome
