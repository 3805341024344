import React from 'react'
import Icons from '../../constants/Icons'
import { motion } from 'framer-motion';

const SocialMedia = ({ SvgColor, styles,
    FacebookData,
    twitterData,
    instagramData,
    youtubeData,
    linkedinData

}) => {


    return (
        <div className={`${styles}`}>
            <motion.a
                href={`${twitterData}`}

                target='_blank'rel="noreferrer"
                whileHover={{ y: -5, scale: 1.2 }}
                transition={{ duration: 0.3 }}
            >
                <Icons.Twitter color={SvgColor} />
            </motion.a>
  {/*           <motion.a
                href={`${FacebookData}`}
                target='_blank'rel="noreferrer"
                whileHover={{ y: -5, scale: 1.2 }}
                transition={{ duration: 0.3 }}
            >
                <Icons.Facebook color={SvgColor} />
            </motion.a> */}
            <motion.a
                href={`${instagramData}`}
                target='_blank'rel="noreferrer"
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
            >
                <Icons.Instagram color={SvgColor} />
            </motion.a>
            <motion.a
                href={`${linkedinData}`}
                target='_blank'rel="noreferrer"
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
            >
                <Icons.Linkedin color={SvgColor} />
            </motion.a>
            <motion.a
                href={`${youtubeData}`}
                target='_blank'rel="noreferrer"
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
            >
                <Icons.Youtube color={SvgColor} />
            </motion.a>
        </div>

    )
}

export default SocialMedia
