import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ProjectServices, ServicesHome } from '../../../Services/ProjectServices';
import LocalizationData from '../../../Translate/LocalizationData';
import Component from '../../../constants/Component';
import { LocalizationContext } from '../../../context/LangChange';
import { Button } from '@mui/material';

// Create a custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // your primary color
        },
        secondary: {
            main: '#FA6409', // your new secondary color
        },
    },
});


const Section3 = () => {
    const [value, setValue] = useState('visual_production');
    let { isLang } = useContext(LocalizationContext);


    const handleChange = (event, newValue) => {

        setValue(newValue);
    };

    const [Data, setData] = useState([])
    const [projectsData, setProjectsData] = useState([])
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        // if (executedProjects) {
        const timeOut = setTimeout(() => {
            setisLoading(false)
            setProjectsData([])
            ProjectServices(isLang, value).then(({ data }) => {
                if (data?.status === 200) {
                    setData(data?.data)
                    setProjectsData(data?.data?.projects);
                    setisLoading(true)
                }
            }).catch((error) => {
            });
        }, 0);
        return () => clearTimeout(timeOut);


    }, [isLang, value])

    const [services, setServices] = useState([])

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setisLoading(false)
            ServicesHome(isLang).then(({ data }) => {
                if (data?.status === 200) { 
                    setServices(data?.data?.services)
                    setisLoading(true)
                }
            }).catch((error) => {
            });
        }, 0);
        return () => clearTimeout(timeOut);
    }, [isLang])
    return (
        <>


            <div className='app__project-home   overflow-hidden bg-light2'  >
                <Container>
                    <Component.Title duration_p={1.5}
                        title={Data?.section_title}
                        textStyle={'text-center'}
                        text={Data?.section_content}
                        width={'w-75'}
                    />
                </Container>
                <ThemeProvider theme={theme}>
                    <Box sx={{ width: '100%', marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="inherit"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            indicatorColor="secondary"
                            // centered={true}
                            aria-label="scrollable auto tabs example"
                        >
                            {
                                services.map((item, index) => (
                                    <Tab style={{ textTransform: 'capitalize', justifyContent: 'center', fontSize: '17px' }} value={item.value} label={item.title} variant={value === item.title ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                                ))
                            }
                        </Tabs>
                    </Box>

                    {value === 'web_development' && (
                        <Box sx={{ p: 2, overflow: 'hidden' }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}

                    {value === 'account_management' && (
                        <Box sx={{ p: 2 }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}

                    {value === 'digital_marketing' && (
                        <Box sx={{ p: 2 }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}

                    {value === 'event_organization' && (
                        <Box sx={{ p: 2 }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}

                    {value === 'visual_production' && (
                        <Box sx={{ p: 2 }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}

                    {value === 'visual_identity' && (
                        <Box sx={{ p: 2 }}>
                            <Component.ProjectsWeb page='home' projectsData={projectsData} isLoading={isLoading} />
                        </Box>
                    )}
                    <div className="pb-5 btn_download">
                        <Component.BtnSeeMore text={LocalizationData[isLang]?.btnMore} fontSize={'16px'} path={isLang == "en" ? '/projects' : '/ar/مشاريعنا'} justifyContent="center" widthBtn={'150px'} />
                        <a href="https://rightclick.com.sa/RightClick_profile_04_compressed.pdf" target="_blank" rel="noopener noreferrer">
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-easing="linear"

                            >
                                <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} flexDirection={`$150px`} justifyContent={`center`}>
                                    <Button 
                                        className='btn__overlay'
                                        variant="contained"
                                        color="warning"
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: `150px !important`,
                                            backgroundColor: '#FA6409',
                                            color: '#24282C',
                                            '&:hover': {
                                                backgroundColor: '#000000',
                                                color: '#ffffff',
                                            }, 
                                            fontSize: `  16px  !important`
                                        }}
                                    >
                                      {isLang === "en" ? 'Profile' : 'ملف تعريــفي '}

                                    </Button>
                                </Box>
                            </div>
                         </a>

                    </div>
                </ThemeProvider>
            </div>


        </>

    )
}

export default Section3
