import AOS from 'aos';
import React, { useContext, useEffect } from 'react';
import { LocalizationContext } from '../context/LangChange';

const Title = ({ title, text, textStyle, width }) => {
    let { isLang } = useContext(LocalizationContext);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])
    return (
        <div className="app__title " dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <h1
                // style={{ fontSize: '16px' }}
                className={` ${textStyle}   mb-3 fw-bold`}
                data-aos="flip-left"
                data-aos-duration="2000"
                data-aos-easing="ease-out-cubic"
            >
                {title}
            </h1>

             <p
                className={`${textStyle} ${'  m-auto'} ${width} `}
                data-aos="flip-right"
                data-aos-duration="2000"
                data-aos-easing="ease-out-cubic"
            >
                {text}
            </p>  
{/* 
            <div className={`${textStyle} p_details_data ${' m-auto'} ${width} `}
                data-aos="flip-right"
                data-aos-duration="2000"
                data-aos-easing="ease-out-cubic"  >
                    <p>{text}</p>
                </div> */}


        </div>
    );
};

export default Title;
