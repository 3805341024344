import Section2 from '../Pages/Home/About/Section2';
import BlogsHome from '../Pages/Home/Blogs/BlogsHome';
import Section3 from '../Pages/Home/Project/Section3';
import BtnSeeMore from './../Components/Buttons/BtnSeeMore';
import Footer from './../Components/Footer/Footer';
import HeaderPages from './../Components/Header/HeaderPages';
import Loading from './../Components/Loading/Loading';
import Loader from './../Components/LoadingNotFound/Loader';
import LoaderBots from './../Components/LoadingNotFound/LoaderBots';
import LoadingPage from './../Components/LoadingNotFound/LoadingPage';
import NavBar from './../Components/NavBar/NavBar';
import Calling from './../Components/SocialMedia/Calling';
import Message from './../Components/SocialMedia/Message';
import SocialMedia from './../Components/SocialMedia/SocialMedia';
import Whatsapp from './../Components/SocialMedia/Whatsapp';
import Title from './../Components/Title';
import RightClick from './../Layout/RightClick';
import RightClickAr from './../Layout/RightClickAr';
import About from './../Pages/About/About';
import Blogs from './../Pages/Blogs/Blogs';
import BlogsDetails from './../Pages/Blogs/BlogsDetails';
import Clints from './../Pages/Clints/Clints';
import ModalClients from './../Pages/Clints/ModalClients';
import ContactUs from './../Pages/ContactUs/ContactUs';
import Section4 from './../Pages/Home/Aniamtions/Section4';
import Section5 from './../Pages/Home/Client/Section5';
import ContactUSHome from './../Pages/Home/ContactUS/ContactUSHome';
import Home from './../Pages/Home/Home';
import ModalProjectsDetails from './../Pages/Home/Project/Details/ModalProjectsDetails';
import ProjectDetails from './../Pages/Home/Project/Details/ProjectDetails';
import ModalProjects from './../Pages/Home/Project/ModalProjects';
import MoreProjects from './../Pages/Home/Project/MoreProjects';
import ProjectsWeb from './../Pages/Home/Project/ProjectsWeb';
import WhatWeDo from './../Pages/Home/WhatWeDo/WhatWeDo';
import Jobs from './../Pages/Jobs/Jobs';
import Projects from './../Pages/Projects/Projects';
import Services from './../Pages/Services/Services';
import NavBarAr from './../Components/NavBarAr/NavBarAr';
import ServiceDetails from './../Pages/Services/ServiceDetails';
import Branding from './../Pages/SEO/Branding';
import NavigationBar from './../Pages/SEO/NavigationBar';
import Seo from './../Layout/Seo';
import ErrorPage from './../Components/LoadingNotFound/ErrorPage';
 

const Component = {
    // Layout
    RightClick,
    Seo,
    Title,
    RightClickAr, 
    ErrorPage,
 
    HeaderPages,
    // Components
    NavBar,
    NavBarAr,
    SocialMedia,
    Calling,
    Loading,
    // Pages
    Home,
    Section2,
    Section3,
    Section4,
    Section5, 
    About,
    Services,
    Clints,
    ContactUs,
    Projects,
    Jobs,
    Message,
    NavigationBar ,
    Branding,
    LoadingPage,
    ProjectsWeb, 
    Footer,
    BtnSeeMore,
    Blogs,
    BlogsDetails,
    Whatsapp,
    BlogsHome,
    ContactUSHome,
    WhatWeDo, 
    ModalProjectsDetails,
    Loader,
    ProjectDetails,
    LoaderBots,
    ModalProjects,
    MoreProjects,
    ModalClients,
    ServiceDetails

}

export default Component


