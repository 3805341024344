import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'

const Loader = () => {
    return (
        <div className='d-flex justify-content-center align-item-center'>
            <Player
                className='w-50'
                autoplay
                loop
                src={'https://lottie.host/7a3c7015-b82f-416a-a379-30e2a55922bf/CNDFEA5EY7.json'}
            />
        </div>
    )
}

export default Loader
