
import LogoMainColor from "./Images/logo-2.png";
import Whatsapp from "./Images/whatsapp.png";

import HeaderAboutUS from "./Images/Header/AboutUS.png";
import HeaderContactUs from "./Images/Header/HeaderContactUs.jpg";
import HeaderOurClients from "./Images/Header/HeaderOurClients.jpg";
import Headerblogs from "./Images/Header/blogs.jpg";
import HeaderService from "./Images/Header/headers.jpeg";
import ImageDefult from "./Images/imageDefult.jpg";
import BlogBg from "./Images/Blogsbg.png";
import seo from "./Images/seo.png";
import Process1 from "./Images/Process (1).png";
import Process2 from "./Images/Process (2).png";
import seoBG from './../assets/Images/seoBG.png';
import seoBG2 from './../assets/Images/seoBG2.png';
import SOCIALMEDIA from './../assets/Images/SOCIALMEDIA.png';
import iconservices1 from './../assets/Images/Service/iconservices (1).png';
import iconservices2 from './../assets/Images/Service/iconservices (2).png';
import iconservices3 from './../assets/Images/Service/iconservices (3).png';
import iconservices4 from './../assets/Images/Service/iconservices (4).png';
import iconservices5 from './../assets/Images/Service/iconservices (5).png';
import iconservices6 from './../assets/Images/Service/iconservices (6).png';
import Crm from './../assets/Images/crm.png';
import tools1 from './../assets/Images/tools1.png';
import tools2 from './../assets/Images/tools2.png';
import tools3 from './../assets/Images/tools3.png';
import tools4 from './../assets/Images/tools4.png';
import tools5 from './../assets/Images/tools5.png';
import tools6 from './../assets/Images/tools6.png';


const img = {
    Crm,
    tools6,
    tools1,
    tools2,
    tools3,
    tools4,
    tools5,
    iconservices1,
    iconservices2,
    iconservices3,
    iconservices4,
    iconservices5,
    iconservices6,
    ImageDefult,
    seo,
    LogoMainColor,
    HeaderAboutUS,
    HeaderContactUs,
    SOCIALMEDIA,
    HeaderService,
    HeaderOurClients,
    Whatsapp,
    BlogBg,
    Process1,
    Process2,
    seoBG,
    seoBG2,
    Headerblogs
}

export default img;
