import Dialog from '@mui/material/Dialog';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../style.scss';

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000000ac',
        },
      },
    },
  },
});
const ModalProjectsDetails = ({ open, projectsData, isLang, handleClose }) => {
  const images = projectsData.map((project) => ({
    original: project.image,
    thumbnail: project.image,
    originalAlt: project.title,
    thumbnailAlt: project.title,
    description: project.title,
    id: project.id,
  }));

  const renderItem = (item) => {
    return (
      <div className="projects">
        <div className="custom-image-gallery-slide">
          <div>
            <img
              src={item.original}
              alt={item.originalAlt}
              className="custom-image-gallery-image object__fit__contain"
            />
          </div>
          {item.description && (
            <div className={`${isLang === "en" ? 'image-gallery-description' : '  image-gallery-descriptionAr'}`} >
              {item.description}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        dir={isLang === 'en' ? 'ltr' : 'rtl'}
        open={open}
        maxWidth="xxl"
        onClose={handleClose}
        BackdropClick={true}
      >
        <div className="modal__image">
          <Container fluid>
            <Row className="mb-3 d-flex justify-content-center align-items-center">
              <ReactImageGallery
                lazyLoad={true}
                items={images}
                infinite={true}
                showBullets={true}
                autoPlay={true}
                thumbnailPosition={'bottom'}
                additionalClass="custom-image-gallery"
                renderItem={renderItem}
              />
            </Row>
          </Container>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalProjectsDetails;
