import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Carousel, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import img from '../../assets/Img';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import SeoAbout from './SeoAbout';
import SeoSlider from './SeoSlider';
import Stage from './Stage';
import './seo.scss';
import ServiceHome from './Service/ServiceHome';
import CustomCMS from './Service/CustomCMS';
import ToolsWeb from './Service/ToolsWeb';
import Languages from './Service/Languages';

const Branding = () => {
    const [data, setData] = useState(null);
    let { lang } = useParams();
    let { isLang } = useContext(LocalizationContext);
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);

    let url = `${process.env.REACT_APP_API_URL}/seo?type=0`;

    if (pathname === '/en/services/branding' || pathname === '/ar/خدماتنا/الهوية') {
        url = `${process.env.REACT_APP_API_URL}/seo?type=0`;
    } else if (pathname === '/en/services/website-development' || pathname === '/ar/خدماتنا/تطوير-الموقع') {
        url = `${process.env.REACT_APP_API_URL}/seo?type=1`;
    } else if (pathname === '/en/services/social-media-marketing' || pathname === '/ar/خدماتنا/تسويق-وسائل-الاعلام-الاجتماعية') {
        url = `${process.env.REACT_APP_API_URL}/seo?type=2`;
    }

    useEffect(() => {
        axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        }).then(response => {
            setData(response.data.data);
        }).catch(error => {
            console.error('Error fetching data: ', error);
        });
    }, [isLang]);
    const capitalizeFirstLetterOfEachWord = (string) => {
        if (!string) return '';
        return string
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase())
            .join(' ');
    };
    const pageTitle = pathname
        ?.replace('/ar/', '')
        ?.replace('/en/', '')
        ?.replace('/services/', '')
        ?.replace('/', ' | ')
        ?.replace(/-/g, ' ') // Replace all hyphens with spaces
        ?.replace(/\/$/, ''); // Remove trailing slash

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{capitalizeFirstLetterOfEachWord(pageTitle)}</title>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className='app__seo_all'>
                <SeoSlider isLang={isLang} data={data?.sliders} Carousel={Carousel} Row={Row} Col={Col} Button={Button} img={img} />
                <SeoAbout isLang={isLang} data={data?.about} Row={Row} Col={Col} Button={Button} img={img} />
            </div>
            {
                (pathname === '/en/services/website-development' || pathname === '/ar/خدماتنا/تطوير-الموقع') &&
                <>
                    <ServiceHome />
                    <CustomCMS />
                </>
            }
            <div className="mt-5">
                <Stage isLang={isLang} />
                {
                    (pathname === '/en/services/website-development' || pathname === '/ar/خدماتنا/تطوير-الموقع') &&
                    <>
                        <ToolsWeb />
                        <Languages/>
                    </>
                }
            </div>
            <Component.ContactUSHome />
        </>
    )
}

export default Branding;
