import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../Components/NavBar/routes';
import img from '../../assets/Img';
import Icons from '../../constants/Icons';
import { FetchDataContext } from '../../context/FetchData';
import { LocalizationContext } from '../../context/LangChange';
const pathMappings = {
    '/en/services/branding': '/ar/خدماتنا/الهوية',
    '/en/services/website-development': '/ar/خدماتنا/تطوير-الموقع',
    '/en/services/social-media-marketing': '/ar/خدماتنا/تسويق-وسائل-الاعلام-الاجتماعية',
    '/ar/خدماتنا/الهوية': '/en/services/branding',
    '/ar/خدماتنا/تطوير-الموقع': '/en/services/website-development',
    '/ar/خدماتنا/تسويق-وسائل-الاعلام-الاجتماعية': '/en/services/social-media-marketing',
};
const NavigationBar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const { settings } = useContext(FetchDataContext);
    const { isLang, setIsLang } = useContext(LocalizationContext);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation">
            <List>
                <div className="d-flex justify-content-center mt-4 mb-4">
                    <img src={img.LogoMainColor} width={150} alt="" />
                </div>
                {routes.map((text, index) => (
                    <div key={index}>
                        {text.subRoutes ? (
                            <>
                                <ListItemButton onClick={handleDropdownClick} sx={{ display: 'flex', gap: '14px' }}>
                                    {text.icon2}
                                    <ListItemText sx={{ color: '#fff' }} primary={isLang === "en" ? text.nameEn : text.nameAr} />
                                    {dropdownOpen ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
                                </ListItemButton>
                                <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {text.subRoutes.map((subRoute, subIndex) => (
                                            <ListItemButton key={subIndex} sx={{ pl: 4 }} onClick={toggleDrawer(false)}>
                                                <Link to={subRoute.path} style={{ textDecoration: 'none', color: 'inherit' }} className='sub_text'>
                                                    <ListItemText sx={{ color: '#FA6409', fontSize: '10px', fontWeight: '300' }} primary={isLang === "en" ? subRoute.nameEn : subRoute.nameAr} />
                                                </Link>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </>
                        ) : (


                            <Link to={text.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItemButton sx={{ display: 'flex', gap: '14px' }}>
                                    {text.icon2}
                                    <ListItemText sx={{ color: '#fff' }} primary={isLang === "en" ? text.nameEn : text.nameAr} />
                                </ListItemButton>
                            </Link>
                        )}
                    </div>
                ))}
            </List>
        </Box>
    );

    const handleLocalizationChange = (action) => {
        if (action === 'en') {
            setIsLang('en');
            window.location.reload();
        } else if (action === 'ar') {
            setIsLang('ar');
            window.location.reload();
        }
    };

    useEffect(() => {
        const pathname = decodeURIComponent(window.location.pathname); 
        let newPath = pathname;

        if (isLang === "en") {
            newPath = pathname
                .replace('/ar/', '/en/')
                .replace('/خدماتنا/', '/services/')
                .replace('/الهوية', '/branding')
                .replace('/تطوير-الموقع', '/website-development')
                .replace('/تسويق-وسائل-الاعلام-الاجتماعية', '/social-media-marketing')
        } else if (isLang === "ar") {
            newPath = pathname
                .replace('/en/', '/ar/')
                .replace('/services/', '/خدماتنا/')
                .replace('/branding', '/الهوية')
                .replace('/website-development', '/تطوير-الموقع')
                .replace('/social-media-marketing', '/تسويق-وسائل-الاعلام-الاجتماعية');
        }

        if (newPath !== pathname) { 
            navigate(newPath, { replace: true });
        } 

    }, [isLang, navigate]);
    return (
        <div className='container_DrawerList'>
            <div className="top-bar">
                <div className="top-bar-content" dir={isLang === "ar" ? 'ltr' : 'rtl'}>
                    <div className="logo-icons">
                        <span className="logo-placeholder">
                            <a className="d-flex justify-content-center align-items-center" href={`mailto:${settings?.email}`} target="_blank" rel="noopener noreferrer">
                                <Icons.Email color={'#ffff'} />
                            </a>
                        </span>
                        <span className="contact-icon">
                            <a className="d-flex justify-content-center align-items-center" href={`tel:${settings?.whats}`} target="_blank" rel="noopener noreferrer">
                                <Icons.ContactUs color={'#ffff'} />
                            </a>
                        </span>
                    </div>
                    {
                        isLang === "en" ?
                            <div className="change-lang" onClick={() => handleLocalizationChange('ar')}>
                                <span className='text'>عربيه</span>
                                <Icons.LangOutline />
                            </div> :
                            <div className="change-lang" onClick={() => handleLocalizationChange('en')}>
                                <span className='text'>English</span>
                                <Icons.LangOutline />
                            </div>
                    }
                    <div className="social-media-icons">
                        <span className="twitter-icon">
                            <a className="d-flex justify-content-center align-items-center" href={settings?.twitter} target="_blank" rel="noopener noreferrer">
                                <Icons.Twitter color={'#ffff'} />
                            </a>
                        </span>
           {/*              <span className="facebook-icon">
                            <a className="d-flex justify-content-center align-items-center" href={settings?.facebook} target="_blank" rel="noopener noreferrer">
                                <Icons.Facebook color={'#ffff'} />
                            </a>
                        </span> */}
                        <span className="instagram-icon">
                            <a className="d-flex justify-content-center align-items-center" href={settings?.instagram} target="_blank" rel="noopener noreferrer">
                                <Icons.Instagram color={'#ffff'} />
                            </a>
                        </span>
                        <span className="linkedin-icon">
                            <a className="d-flex justify-content-center align-items-center" href={settings?.linked_in} target="_blank" rel="noopener noreferrer">
                                <Icons.Linkedin color={'#ffff'} />
                            </a>
                        </span>
                        <span className="linkedin-icon">
                            <a className="d-flex justify-content-center align-items-center" href={settings?.youtube} target="_blank" rel="noopener noreferrer">
                                <Icons.Youtube color={'#ffff'} />
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="lower-nav" dir={isLang === "ar" ? 'ltr' : 'rtl'}>
                <div className='d-flex w-100 justify-content-start gap-3 align-items-center'>
                    <Icons.Menu className='icons__menu' onClick={toggleDrawer(true)} />
                    <Link to={'contactus'}>
                        <button className="contact-button">{isLang === "en" ? 'let’s Talk' : 'اسألنا الحين'}</button>
                    </Link>
                </div>
                <Drawer
                    open={open}
                    anchor={isLang === 'ar' ? 'right' : 'left'}
                    sx={{
                        '& .MuiDrawer-paper': {
                            backgroundColor: '#000',
                            color: '#FFF',
                        },
                    }}
                    onClose={toggleDrawer(false)}
                >
                    {DrawerList}
                </Drawer>
            </div>
        </div>
    );
};

export default NavigationBar;
