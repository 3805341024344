import React from 'react';
// import ScrollTrigger from 'react-scroll-trigger';
import './style.scss';


const Section4 = () => {
    // let { isLang } = useContext(LocalizationContext);

    // const handleScroll = (isVisible) => {
    //     if (isVisible) {
    //         document.getElementById('circle').classList.add('animate-circle');
    //         document.getElementById('circle2').classList.add('animate-circle2');
    //         document.getElementById('circle3').classList.add('animate-circle3');
    //     }
    // };
    // useEffect(() => {
    //     handleScroll()

    // }, [])

    return (
        <>
 {/*            <div className="app__animation-right"  >
                <Container>
                    <Component.Title
                        duration_p={1.5}
                        title={LocalizationData[isLang].Visions.Title}
                        text={LocalizationData[isLang].Visions.Text}
                        textStyle={'text-center'}
                    />
                </Container>
            </div>
            <ScrollTrigger onEnter={handleScroll}>
                <div className="app__draw">
                    <div id="circle" className="circle ">

                        <div id="circle2" className="circle2">
                            <motion.div whileInView={{ opacity: [0, 1], transition: { delay: 1.2 } }} className="dot4" />
                            <motion.div whileInView={{ transition: { delay: 1.5 } }} className="service4 ">
                                <Link to={'/service'}>
                                    <motion.div whileInView={{ scale: [0, 1], transition: { duration: 1, type: 'tween' } }}  >
                                        <h6 className='title__service'>  Web <br /> Development </h6>
                                        <img src={img.Service5_Web_Development} className='w-100' alt="" />
                                    </motion.div>
                                </Link>
                            </motion.div>
                        </div>
                        <div id="circle3" className="circle3">
                            <motion.div whileInView={{ opacity: [0, 1], transition: { delay: 1.2 } }} className="dot5" />
                            <Link to={'/service'}>

                                <motion.div whileInView={{ scale: [0, 1], transition: { duration: 1, delay: 1, type: 'just' } }} className="service5">
                                    <h6 className='title__service'>Event <br /> Organization</h6>
                                    <img src={img.Service3_Event_Organization} className='w-100' alt="" />
                                </motion.div>
                            </Link>
                        </div>
                        <Link to={'/service'}>
                            <motion.div whileInView={{ scale: [0, 1], transition: { duration: 1, delay: 0.7, type: 'just' } }} className="service1 ">
                                <h6 className='title__service'>Digital <br /> Marketing  </h6>
                                <img src={img.Service1_Digital_Marketing} className='w-100' alt="" />

                            </motion.div>
                        </Link>
                        <Link to={'/service'}>
                            <motion.div whileInView={{ scale: [0, 1], transition: { duration: 1, delay: 1.5, type: 'just' } }} className="service2">
                                <h6 className='title__service'>Visual <br /> Identity</h6>
                                <img src={img.Service4_Visual_Identity} className='w-100' alt="" />
                            </motion.div>
                        </Link>
                        <Link to={'/service'}>
                            <motion.div whileInView={{ scale: [0, 1], transition: { duration: 1, delay: 2, type: 'just' } }} className="service3">
                                <h6 className='title__service'>Visual <br /> Producrion  </h6>
                                <img src={img.Service2_Visual_Producrion} className='w-100' alt="" />
                            </motion.div>
                        </Link>

                        <motion.div whileInView={{ opacity: [0, 1], transition: { delay: 1.2 } }} className="dot1" />
                        <motion.div whileInView={{ opacity: [0, 1], transition: { delay: 1.2 } }} className="dot2" />
                        <motion.div whileInView={{ opacity: [0, 1], transition: { delay: 1.2 } }} className="dot3" />
                    </div>
                    <div className="title">
                        <motion.h1
                            className='right__click-title  '
                            whileInView={{
                                scale: [0, 0.5, 1, 1.5, 1.7, 1.5, 1, 0.5, 0],
                                // rotate: [0, 0, 180, 180, 0], 
                                transition: {
                                    duration: 3,
                                    ease: "linear",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                    repeat: Infinity,
                                    repeatDelay: 1,
                                    delay: 2

                                }
                            }}
                        > Right Click
                        </motion.h1>
                    </div>
                    <div className="aniamtion1">
                        <img src={img.animationPNG1} alt="" />
                    </div>

                    <div className="aniamtion2">
                        <img src={img.animationPNG1} alt="" />
                    </div>


                </div>


            </ScrollTrigger> */}


        </>
    );
}

export default Section4;
