import axios from "axios";
//Home Services
export const HomeServices = async (values) => {
    const url = `${process.env.REACT_APP_API_URL}/home/sliders`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': values,
        }
    }).catch((err) => {
    })
    return data
};
export default HomeServices  