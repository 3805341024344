import { Player } from '@lottiefiles/react-lottie-player'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import Component from '../../constants/Component'
import './jops.scss'
import Icons from '../../constants/Icons'
import { LocalizationContext } from '../../context/LangChange'
import LocalizationData from '../../Translate/LocalizationData'
import { Alert, Box, Button } from '@mui/material'
import { motion } from 'framer-motion';
import img from '../../assets/Img'
import { JopsServices } from '../../Services/ContantServices'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  country: Yup.string().required('Country is required'),
  job_title: Yup.string().required('Job title is required'),
  resume: Yup.mixed().required('Resume is required'),
});
const Jobs = () => {
  let { isLang } = useContext(LocalizationContext)
  const [meassageApi, setMeassageApi] = useState(null)



  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      country: '',
      job_title: '',
      resume: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => { 
      await JopsServices(isLang, values).then(({ data }) => { 
        setMeassageApi(data?.message)
        resetForm();
      })
    }
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isLang === "en" ? 'Career' : '  وظائف'}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Component.HeaderPages
        img={img.HeaderAboutUS}
        title={LocalizationData[isLang]?.Jops.Title}
        text={LocalizationData[isLang]?.Blogs.Text}
      />
      <div className="app__jops">
        <div className=" mt-5">
          <Container>
            <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
              <Col xl={7} lg={7} md={12} sm={12}>
                {
                  meassageApi === null ?
                    <Form className="form" onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="label-style">  {LocalizationData[isLang].Jops.label1}  </Form.Label>
                            <Form.Control
                              className="input-style"
                              type="text"
                              placeholder={LocalizationData[isLang].Jops.placeholder}
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                              isInvalid={formik.touched.name && formik.errors.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className="error">{formik.errors.name}</div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={12} lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label className="label-style">  {LocalizationData[isLang].Jops.label2} </Form.Label>
                            <Form.Control
                              className="input-style"
                              type="email"
                              placeholder="info@"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              isInvalid={formik.touched.email && formik.errors.email}

                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="error">{formik.errors.email}</div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={12} lg={12} md={12} sm={12}>
                          <Form.Group controlId="formBasicEmail" className='mt-3 '>
                            <Form.Label>{LocalizationData[isLang].Jops.label3}</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="job_title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.job_title}
                            >
                              <option selected disabled hidden value=""  >   {LocalizationData[isLang].Jops.selectPosition}   </option>
                              <option value="Graphic Designer">Graphic Designer</option>
                              <option value="UI/UX Designer">UI/UX Designer</option>
                              <option value="Mobile app developer">Mobile app developer</option>
                              <option value="Backend Developer">Backend Developer</option>
                              <option value="Frontend Developer">Frontend Developer</option>
                              <option value="Copywriter">Copywriter</option>
                              <option value="Moderator">Moderator</option>
                              <option value="Account Manager">Account Manager</option>
                              <option value="Project Manager">Project Manager</option>
                              <option value="Accountant">Accountant</option>
                              <option value="SEO">SEO</option>
                              <option value="ASO">ASO</option>
                              <option value="Digital Marketing Specialist">Digital Marketing Specialist</option>
                              <option value="Analyst">Analyst</option>
                            </Form.Select>
                            {formik.touched.job_title && formik.errors.job_title && (
                              <div className="error">{formik.errors.job_title}</div>
                            )}

                          </Form.Group>
                        </Col>

                        <Col xl={12} lg={12} md={6} sm={12} className='mt-3 '>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>{LocalizationData[isLang].Jops.label4}</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="country"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}
                            >
                              <option selected disabled hidden value="">
                                {LocalizationData[isLang].Jops.selectCountry}
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Egypt">Egypt</option>
                            </Form.Select>
                            {formik.touched.country && formik.errors.country && (
                              <div className="error">{formik.errors.country}</div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                            <Form.Label className='label-style'>{LocalizationData[isLang].Jops.label5}</Form.Label>
                            <div>
                              <input
                                type="file"
                                id="file-input"
                                accept=" pdf/*"
                                onChange={(event) => {
                                  formik.setFieldValue('resume', event.currentTarget.files[0]);
                                }}
                                style={{ display: 'none' }}
                              />
                              <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                                <Icons.Upload />
                                <span>{formik.values.resume ? formik.values.resume.name : LocalizationData[isLang].Jops.labelUpload}</span>

                              </label>
                              {formik.touched.resume && formik.errors.resume && (
                                <div className="error">{formik.errors.resume}</div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>

                        <div className=" d-flex justify-content-center">
                          <Button
                            type='submit'
                            className='btn__overlay'
                            variant="contained"
                            color="warning"
                            sx={{
                              textTransform: 'capitalize',
                              backgroundColor: '#FA6409',
                              color: '#24282C',
                              '&:hover': {
                                backgroundColor: '#000000',
                                color: '#ffffff',
                              },
                              marginTop: '15px',
                              width: '200px'

                            }}

                          >
                            {LocalizationData[isLang].btnSubmit}
                          </Button>
                        </div>

                      </Row>
                    </Form> :
                    <>
                      <Alert variant='standard'>{meassageApi}</Alert>
                      <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'center'} >
                        <Button
                          type="submit"
                          className='btn__overlay'
                          variant="contained"
                          color="warning"
                          sx={{
                            marginBottom: '20px',
                            textTransform: 'capitalize',
                            backgroundColor: '#FA6409',
                            color: '#24282C',
                            '&:hover': {
                              backgroundColor: '#000000',
                              color: '#ffffff',
                            },
                            fontSize: `16px !important`
                          }}
                          onClick={() => setMeassageApi(null)}
                        >
                          {isLang === "en" ? 'Do you want send anthor messange' : 'هل تريد ارسال طلب اخري'}
                        </Button>
                      </Box>
                    </>
                }
              </Col>
              <Col xl={5} lg={5} md={12} sm={12}>
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  transition={{ duration: 1, type: 'tween' }}
                >
                  <Player
                    autoplay
                    loop
                    src={'https://assets10.lottiefiles.com/packages/lf20_ceiqy4t3.json'}
                  />
                </motion.div>
              </Col>
            </Row>
          </Container>
          <Component.Footer />
        </div>
      </div>
    </>
  )
}

export default Jobs