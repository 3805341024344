import AOS from 'aos'
import { motion } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LocalizationFooter from '../../Translate/LocalizationFooter'
import img from '../../assets/Img'
import Component from '../../constants/Component'
import Icons from '../../constants/Icons'
import { FetchDataContext } from '../../context/FetchData'
import { LocalizationContext } from '../../context/LangChange'
import './Footet.scss'

const Footer = () => {
    let { settings } = useContext(FetchDataContext)
    let { isLang } = useContext(LocalizationContext)
    useEffect(() => {
        AOS.init({
            once: true,
        });
        AOS.refresh();
    }, [])
    return (
        <div className="footer-top">
            {
                settings &&
                <div className='app__Footer'>
                    <div className="overlayer"></div>
                    <Container>
                        <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <Col xl={4} lg={4} md={6} sm={12} className=' content1 mt-3      '
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-duration="1500"
                                data-aos-easing="ease-out-cubic">
                                <div >
                                    <img src={img.LogoMainColor} width={"200px"} className='mb-4' alt="logo right click" />
                                    <br />

                                    <span className='app__footer-p '>{settings?.description} </span>
                                    <hr className='line__footer' />
                                    <div className="social">
                                        <span>{LocalizationFooter[isLang].follow}</span>
                                        <div className="social__icons">
                                            <Component.SocialMedia
                                                FacebookData={settings?.facebook}
                                                twitterData={settings?.twitter}
                                                instagramData={settings?.instagram}
                                                youtubeData={settings?.youtube}
                                                linkedinData={settings?.linked_in}
                                                SvgColor={'#FFFFFF'}
                                                styles={'group_scoial-media-notPosotions'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xl={2} lg={2} md={6} sm={12} className='  content2 mt-3' >
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-easing="ease-out-cubic"
                                >
                                    <h1>{LocalizationFooter[isLang].TitleQuickLinks}</h1>
                                    <div className="footer-ui">
                                        <ul  >
                                            {LocalizationFooter[isLang].QuickLinks.map((item, index) => (
                                                <Link to={isLang == "en" ? item.path : item.pathAr} key={index}>
                                                    <li >
                                                        {item.text}
                                                    </li>
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={2} lg={2} md={6} sm={12} className='  content3 mt-3' >
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-easing="ease-out-cubic"
                                >
                                    <h1>  {LocalizationFooter[isLang].TitleService}</h1>

                                    <div className="footer-ui">
                                        <ul>
                                            {
                                                LocalizationFooter[isLang].service.map((item, index) => (
                                                    <Link to={isLang == "en" ? item.path : item.pathAr}  key={index}>
                                                        <li onClick={() => localStorage.setItem('activeService', item.type)} >
                                                            {item.text}
                                                        </li>
                                                    </Link>
                                                ))
                                            } 
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className=' content4 mt-3' >
                                <div data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-easing="ease-out-cubic">
                                    <h1>  {LocalizationFooter[isLang].TitleContactUS}</h1>
                                    <div className="app__footer-contact-us">
                                        <a className='content-Contact-Us'
                                            href="https://www.google.com/maps/place/24%C2%B044'20.8%22N+46%C2%B038'48.4%22E/@24.7390256,46.6458355,18.85z/data=!4m4!3m3!8m2!3d24.739117!4d46.646789?entry=ttu"
                                            target="_blank" rel="noreferrer">
                                            <motion.div
                                                className="phoneIcons round d-flex justify-content-center align-items-center "
                                                whileHover={{ rotate: 360 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Icons.Location />
                                            </motion.div>
                                            <p className="m-0 p-0">
                                                {settings?.address}
                                            </p>
                                        </a>
                                        <a href={`tel:${settings?.phone}`} target='_blank' rel="noreferrer" className='m-0 p-0 content-Contact-Us'>
                                            <motion.div
                                                className="phoneIcons round d-flex justify-content-center align-items-center "
                                                whileHover={{ rotate: 360 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Icons.Phone />
                                            </motion.div>
                                            <span dir='ltr'>{' + '}{settings?.phone?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>
                                        </a>
                                        <a href={`tel:${settings?.whats}`} target='_blank' rel="noreferrer" className='m-0 p-0  content-Contact-Us'>
                                            <motion.div
                                                className="phoneIcons round d-flex justify-content-center align-items-center "
                                                whileHover={{ rotate: 360 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Icons.Telephone />
                                            </motion.div>
                                            <span dir='ltr'>{settings?.whats?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={`mailto: ${settings?.email}`} className='m-0 p-0  content-Contact-Us'>
                                            <motion.div
                                                className="phoneIcons-email round d-flex justify-content-center align-items-center "
                                                whileHover={{ rotate: 360 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Icons.Email2 />
                                            </motion.div>
                                            <span>{settings?.email}</span>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr className='line__footer' />
                        <div className="footer__copyright  d-flex justify-content-center align-items-center">
                            <p>  &copy; 2023  right click</p>
                        </div> 
                    </Container>

                </div>
            }

            <div className="top  "
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1500" onClick={() => window.scroll(0, 0)} >
                <Icons.TopArrow />
            </div>
        </div>
    )
}

export default Footer
