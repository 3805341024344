import React from 'react'
import Component from '../../constants/Component'

const LoadingPage = () => {
    return (
        <div>
            <div div className="loading">
                <Component.Loader />
            </div>
        </div>
    )
}

export default LoadingPage
