import { Tooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import ClientsService from '../../Services/ClientsService';
import img from '../../assets/Img';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import './clients.scss';
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000',
          color: '#fff',
          fontSize: '16px',
          fontFamily: 'monospace'
        },
        arrow: {
          color: '#000',
        },
      },
    },
  },
});

const Clints = () => {
  let { isLang } = useContext(LocalizationContext)
  const [data, setData] = useState([])
  const [imageIndex, setImageIndex] = useState(0)
  const [clientData, setClientData] = useState([])
  const [loading, setloading] = useState(false)
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (number) => {
    setOpen(true)
    setImageIndex(number)
  }
  const handleClose = () => setOpen(false)

  useEffect(() => {
    window.scroll(0, 0)
    const timeOut = setTimeout(() => {
      setloading(false)
      ClientsService(isLang).then(({ data }) => {
        if (data?.status === 200) {
          setloading(true)
          setData(data?.data)
          setClientData(data?.data?.clients)
        }
      }).catch((error) => {
      });
    }, 200);
    return () => clearTimeout(timeOut);
  }, [isLang])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isLang === "en" ? 'Our Clients' : 'عملائنا'}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {
        loading ?
          <>
            <Component.HeaderPages
              img={img.HeaderOurClients}
              title={data?.section_title}
              text={data?.section_content}
            />
            <div className=" mt-5 app__ourClicnts mb-5" /* style={{ position: 'relative', minHeight: '100vh' }} */>

              <Container>
                <Row>
                  {clientData?.slice().reverse().map((company, index) => (
                    <Col key={index} xl={2} lg={2} md={2} sm={4} className='d-flex align-item-center justify-content-center mt-5'>
                      <ThemeProvider theme={theme}>

                        <Tooltip title={company?.title} arrow sx={{ backgroundColor: '#fff', color: '#fff' }}>

                          <motion.img
                            onClick={() => handleClickOpen(index)}
                            style={{ cursor: 'pointer', objectFit: 'contain', objectPosition: '50% 50%' }}
                            src={company.image}
                            width={'50%'}
                            alt={company.title}
                            whileInView={{
                              scale: [0, 1],
                              transition: {
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                delay: 0.5,
                                transition: 0.5
                              }
                            }}
                          />
                        </Tooltip>
                      </ThemeProvider>
                    </Col>
                  ))}
                </Row>
              </Container>

            </div>
            <Component.Footer />
          </> : <Component.LoadingPage />
      }

      <Component.ModalClients open={open} projectsData={clientData} initialImageIndex={imageIndex} isLang={isLang} handleClose={handleClose} />

    </>
  );
};

export default Clints;
