let LocalizationData = {
    "en": {
        btnMore: 'See More',
        btnDetails: 'Details',
        btnSubmit: 'Submit  ',
        slider: {
            web: 'Web Development',
            web2: 'Build You Website To Attract The Right Client.',
            Event: ' Event Organization',
            Event2: 'We Market And Manage Your Platforms',
            Visual: ' Visual Production',
            Visual2: 'We Market And Manage Your Platforms',
            Marketing: ' Digital Marketing',
            Marketing2: 'Build You Website To Attract The Right Client.',
            btn: 'Make Order'
        },
        About: {
            title: 'Who We Are ?',
            text: 'A Saudi Company Specialzed In The Field Of Digital Marketing And In The Managmenet And Enrichment Of Contrnt On Social Networks, In Addotion To Designing Websites, Organizing Event And Many Services Provided By Innovative, Creative, And Specialized Elite Group',
            summaryProject: 'Project',
            summaryClients: 'Clients',
            summaryExperience: 'Experience',
            summaryYear: 'Years',
            AboutTitle1: `Our mission`,
            AboutText1: 'A Saudi Company Specialzed In The Field Of Digital Marketing And In The Managmenet And Enrichment Of Contrnt On Social Networks  ',
            AboutTitle2: `Our Visions`,
            AboutText2: 'A Saudi Company Specialzed In The Field Of Digital Marketing And In The Managmenet And Enrichment Of Contrnt On Social Networks  ',
            AboutMore: '  know more '

        },

        Project: {
            Title: 'Our Projects ',
            Text: 'Our Team Is Distinguished By A High Level Of Competence And Experience In All Disciplines, In Addition To Long Experience And Professional Work ',
            Website: 'Web Development',
            Mobile: 'Account management  ',
            Marketing: 'Digital Marketing  ',
            Organization: '   Event Organization',
            Visual: 'Visual Production',
            VisualIdentity:'Visual Identity'
        },

        Visions: {
            Title: 'What We Do ?',
            Text: 'Our Team Is Distinguished By A High Level Of Competence And Experience In All Disciplines, In Addition To Long Experience And Professional Work',
            Website: 'Web Development',
            Mobile: 'Account management  ',
            Marketing: 'Digital Marketing  ',
            Organization: '   Event Organization',
            Visual: 'Visual Production',
        },

        Clients: {
            Title: 'Our Clients',
            Text: 'A saudi company specialzed in the field of digital marketing and in the managmenet and enrichment of  '
        },

        Blogs: {
            Title: 'Blogs',
            Text: 'Very Interested In Customer Evaluation To Help Us Improve And Provide Our Best '
        },

        Feedback: {
            Title: 'Customer Feedback',
            Text: 'Very Interested In Customer Evaluation To Help Us Improve And Provide Our Best'
        },

        ContactUs: {
            title: 'Contact Us',
            label1: 'Name',
            Placeholder1: 'Enter you name',
            label2: 'Email',
            Placeholder2: 'Enter you email',
            label3: 'Phone', 
            label4: 'Communication Reason',
            Placeholder4: 'What is the reason for calling',
            label5: 'Your Messange',
            Placeholder5: '  Leave your message and wait for a response ',
            btnSend: 'Send',
            btnDownload:'Download Company Profile'
        },

        Jops: {
            Title: 'Jobs',
            label1: 'Name',
            placeholder:'enter your name',
            label2: 'Email',
            label3: 'postions',
            selectPosition:'Choose The Job Title', 
            label4: 'Country',
            selectCountry:'Choose your country',
            label5: 'Upload your resume',
            labelUpload: 'Upload'
        },

        Team:{
            Title: 'our team',
            Text: `our team is distinguished by a high level of competence and experience in all `
        },

        Service:{
            Title:'our servies',
            Text:'our team is distinguished by a high level of competence and experience in all disciplines, in addition to long experience and professional work'
        }
    },
    "ar": {
        btnMore: 'شاهد المزيد  ',
        btnSubmit: 'إرسال  ',
        btnDetails: 'تفاصيل',

        slider: {
            web: 'تطوير المواقع',
            web2: 'أنشئ موقعًا إلكترونيًا لجذب العميل المناسب',
            Event: ' تنظيم الأحداث',
            Event2: 'نقوم بتسويق وإدارة المنصات الخاصة بك',
            Visual: ' الإنتاج المرئي',
            Visual2: 'نقوم بتسويق وإدارة المنصات الخاصة بك',
            Marketing: ' التسويق الرقمي',
            Marketing2: 'أنشئ موقعًا إلكترونيًا لجذب العميل المناسب.',
            btn: '  اطلــب الخـدمــة '

        },

        About: {
            title: 'من نـــحن ؟',
            text: 'شركة سعودية متخصصة في مجال التسويق الرقمي وإدارة وإثراء المحتوى على الشبكات الاجتماعية ، بالإضافة إلى تصميم المواقع الإلكترونية وتنظيم الفعاليات والعديد من الخدمات التي تقدمها مجموعة النخبة المبتكرة والإبداعية والمتخصصة ',
            summaryProject: 'المشــاريع',
            summaryClients: 'العمــلاء',
            summaryExperience: 'خبـــرة',
            summaryYear: 'سنــــوات',
            AboutTitle1: `رسالتنا   `,
            AboutText1: 'شركة سعودية متخصصة في مجال التسويق الرقمي وإدارة وإثراء المحتوى على الشبكات الاجتماعية',
            AboutTitle2: ` رؤيتنا`,
            AboutText2: 'شركة سعودية متخصصة في مجال التسويق الرقمي وإدارة وإثراء المحتوى على الشبكات الاجتماعية',
            AboutMore: 'تعرف أكثر'
        },

        Project: {
            Title: 'مشاريعنا',
            Text: 'يتميز فريقنا بمستوى عالٍ من الكفاءة والخبرة في جميع التخصصات ، بالإضافة إلى الخبرة الطويلة والعمل الاحترافي',
            Website: 'تطوير المواقع',
            Mobile: '   ادارة الحساب  ',
            Marketing: ' التسويق الرقمي  ',
            Organization: '    تنظيم الأحداث',
            Visual: ' الإنتاج المرئي',
            VisualIdentity:'الهوية المرئية'

        },

        Visions: {
            Title: 'مــا نقوم بيه؟',
            Text: 'يتميز فريقنا بمستوى عالٍ من الكفاءة والخبرة في جميع التخصصات ، بالإضافة إلى الخبرة الطويلة والعمل الاحترافي',
            Website: 'تطوير المواقع',
            Mobile: '   ادارة الحساب  ',
            Marketing: ' التسويق الرقمي  ',
            Organization: '    تنظيم الأحداث',
            Visual: ' الإنتاج المرئي',
        },

        Clients: {
            Title: 'عملائنا  ',
            Text: 'شركة سعودية متخصصة في مجال التسويق الرقمي وإدارة وإثراء'
        },

        Blogs: {
            Title: 'مقالات',
            Text: 'تعــرف علــي خدماتــنا و انضــم الــي عملائــنا '
        },

        Feedback: {
            Title: 'ملاحظات العملاء',
            Text: 'تعــرف علــي خدماتــنا و انضــم الــي عملائــنا '
            
        },

        ContactUs: {
            title: 'اتصل بنا',
            label1: 'اسم',
            Placeholder1: 'ادخل لك اسم',
            label2: 'بريد إلكتروني',
            Placeholder2: 'أدخل بريدك الإلكتروني',
            label3: 'هاتف',
            Placeholder4: '+996',
            label4: 'سبب الاتصال',
            Placeholder4: 'ما سبب الاتصال',
            label5: 'رسالتك',
            Placeholder5: '  اترك رسالتك وانتظر الرد ',
            btnSend: 'إرســال',
            btnDownload:'تحميل الملف التعريفي للشركة'

        },

        Jops: {
            Title: 'وظائف',
            label1: 'اسم',
            placeholder:'أدخل اسمك',
            label2: 'بريد إلكتروني',
            label3: 'مناصب',
            selectPosition:'اختر المسمى الوظيفي',
            label4: 'دولة',
            selectCountry:'اختر بلدك', 
            label5: 'تحميل السيرة الذاتية',
            labelUpload: 'تحميل'
        },
        Team:{
            Title: 'فريقنا',
            Text: `يتميز فريقنا بمستوى عالٍ من الكفاءة والخبرة في الكل `
        },
        
        Service:{
            Title:'خدماتنا',
            Text:'يتميز فريقنا بمستوى عالٍ من الكفاءة والخبرة في جميع التخصصات ، بالإضافة إلى الخبرة الطويلة والعمل الاحترافي'
        }

    }
}

export default LocalizationData