import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Component from './constants/Component';
import FetchData from './context/FetchData';
import LangChange from './context/LangChange';
import './style/App.scss';

const App = () => {
  const [isLang, setIsLang] = useState(localStorage.getItem('langChange'));
  useEffect(() => {
    if (!localStorage.getItem('langChange')) {
      setIsLang('en')
      return localStorage.setItem('langChange', 'en')
    } else {
      return localStorage.setItem('langChange', isLang)
    }
  }, [isLang])

  function ProtectedRoutes({ children }) {
    if (localStorage.getItem('langChange') === "en") {
      return children
    } else {
      return <Navigate to="/ar" replace={true} />
    }
  }
  function ProtectedRoutesAr({ children }) {
    if (localStorage.getItem('langChange') === "ar") {
      return children
    } else {
      return <Navigate to="/" replace={true} />
    }
  }
  const root = createBrowserRouter([
    {
      path: `/`, element: <Component.RightClick />, children: [
        { index: true, element: <ProtectedRoutes><Component.Home /></ProtectedRoutes> },
        { path: 'home', element: <Component.Home /> },
        { path: 'about-us', element: <ProtectedRoutes><Component.About /></ProtectedRoutes> },
        {
          path: 'service', children: [
            { index: true, element: <Component.Services /> },
            { path: ':id', element: <Component.ServiceDetails /> }
          ]
        },
        {
          path: 'projects', children: [
            { index: true, element: <ProtectedRoutes><Component.Projects /> </ProtectedRoutes> },
            { path: ':id', element: <ProtectedRoutes><Component.ProjectDetails /> </ProtectedRoutes> }
          ]
        },
        { path: 'jobs', element: <ProtectedRoutes><Component.Jobs /></ProtectedRoutes> },
        { path: 'clients', element: <ProtectedRoutes><Component.Clints /></ProtectedRoutes> },
        { path: 'contactus', element: <ProtectedRoutes><Component.ContactUs /></ProtectedRoutes> },
        {
          path: 'blogs', children: [
            { index: true, element: <ProtectedRoutes><Component.Blogs /></ProtectedRoutes> },
            { path: ':id', element: <ProtectedRoutes><Component.BlogsDetails /></ProtectedRoutes> }
          ]
        },
        {
          path: 'serviceContact', children: [
            { path: ':image/:id', element: <ProtectedRoutes><Component.ContactUs /></ProtectedRoutes> }
          ]
        },
        { path: '*', element: <Component.Home /> },

      ],
    },

    {
      path: `/:lang`, // This will capture the language code as a parameter
      children: [
        {
          path: `services`,
          element: <Component.Seo />,
          children: [
            { path: 'branding', element: <Component.Branding /> },
            { path: 'website-development', element: <Component.Branding /> },
            { path: 'social-media-marketing', element: <Component.Branding /> },

            { path: 'العلامات-التجارية', element: <Component.Branding /> },
            { path: 'تطوير-الموقع', element: <Component.Branding /> },
            { path: 'تسويق-وسائل-الاعلام-الاجتماعية', element: <Component.Branding /> },
            { path: '*', element: <Component.Home /> },
          ],
        }
      ]
    },

    {
      path: `/:lang`, // This will capture the language code as a parameter
      children: [
        {
          path: `خدماتنا`,
          element: <Component.Seo />,
          children: [ 
            { path: "الهوية", element: <Component.Branding /> },
            { path: 'تطوير-الموقع', element: <Component.Branding /> },
            { path: 'تسويق-وسائل-الاعلام-الاجتماعية', element: <Component.Branding /> },
            { path: '*', element: <Component.Home /> },
          ],
        }
      ]
    }, 
    {
      path: `/:lang`, // This will capture the language code as a parameter
      children: [
        {
          path: `services`,
          element: <Component.Seo />,
          children: [
            { path: 'branding', element: <Component.Branding /> },
            { path: 'website-development', element: <Component.Branding /> },
            { path: 'social-media-marketing', element: <Component.Branding /> },
            { path: '*', element: <Component.Home /> },
          ],
        }
      ]
    },

    {
      path: `/ar`, element: <Component.RightClickAr />, children: [
        { index: true, element: <ProtectedRoutesAr><Component.Home /></ProtectedRoutesAr> },
        { path: '/ar/الصفحة-الرئيسية', element: <ProtectedRoutesAr><Component.Home /></ProtectedRoutesAr> },
        { path: '/ar/نبذة-عنا', element: <ProtectedRoutesAr><Component.About /></ProtectedRoutesAr> },


        {
          path: '/ar/خدماتنا', children: [
            { index: true, element: <ProtectedRoutesAr><Component.Services /></ProtectedRoutesAr> },
            { path: ':id', element: <ProtectedRoutesAr><Component.ServiceDetails /></ProtectedRoutesAr> }
          ]
        },
        {
          path: '/ar/مشاريعنا', children: [
            { index: true, element: <ProtectedRoutesAr><Component.Projects /></ProtectedRoutesAr> },
            { path: ':id', element: <ProtectedRoutesAr><Component.ProjectDetails /></ProtectedRoutesAr> }
          ]
        },
        { path: '/ar/وظائف', element: <ProtectedRoutesAr><Component.Jobs /></ProtectedRoutesAr> },
        { path: '/ar/عملائنا', element: <ProtectedRoutesAr><Component.Clints /></ProtectedRoutesAr> },
        { path: '/ar/اتصل-بنا', element: <ProtectedRoutesAr><Component.ContactUs /></ProtectedRoutesAr> },
        {
          path: '/ar/المدونات', children: [
            { index: true, element: <ProtectedRoutesAr><Component.Blogs /></ProtectedRoutesAr> },
            { path: ':id', element: <ProtectedRoutesAr><Component.BlogsDetails /></ProtectedRoutesAr> }
          ]
        },
        {
          path: '/ar/الاتصال-بالخدمة', children: [
            { path: ':image/:id', element: <ProtectedRoutesAr><Component.ContactUs /></ProtectedRoutesAr> }
          ]
        },
        { path: '*', element: <Component.Home /> },

      ],
    },
  ])


  return (
    <HelmetProvider>
      <LangChange>
        <FetchData>
          <RouterProvider router={root} />
        </FetchData>
      </LangChange>
    </HelmetProvider>
  )
}

export default App
