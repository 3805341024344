import React, { useContext, useEffect } from 'react';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import './style.scss';
import { Link } from 'react-router-dom';

const HeaderPages = ({ img, text, title, link, linkAr, path,pathAr }) => {
    const bgStyle = {
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '70%',
        width: '100%',
        objectFit: 'cover',
    };

    let { isLang } = useContext(LocalizationContext)

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div>
            <header className="header__page main-img-bg" style={bgStyle} >
                <div className="total-header layer-img d-flex justify-content-end   align-items-end " >
                    <div className="container " style={{ marginBottom: '143px' }}>
                        <div className={`main-title-page d-flex`} dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <Component.Title
                                duration_p={1.5}
                                width={'w-100'}
                                title={title}
                                text={text}
                                textStyle={'text-left'}
                            />
                            {
                                link &&
                                <Link to={`${isLang=="en"?path:pathAr}`}>{isLang === "en" ? link : linkAr}</Link>
                            }
                            {/* <h2 className="fs-1 text-white fw-bold">{isLang === 'en' ? 'About Us' : 'مـن نحـن؟ '}</h2> */}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default HeaderPages