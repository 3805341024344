import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import img from '../../assets/Img';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';

const ServiceDetails = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    const [isLoading, setisLoading] = useState(false)
    const [Data, setData] = useState([])
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);
    const url = `${process.env.REACT_APP_API_URL}/home/services/${id}`;

    const fetchData = async () => {
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        })

        if (data?.status === 200) {
            setData(data?.data);
            setisLoading(true)
        }
    }
    useEffect(() => {
        fetchData()
    }, [isLang])
    const pageTitle = pathname
        ?.replace('/ar/', '')
        ?.replace('/service', 'Service')
        ?.replace('/', ' | ')
        ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
        ?.replace(/-/g, ' '); // Replace all hyphens with spaces

    return (
        <div className='app__services_details'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            {
                isLoading ? <>
                    <Component.HeaderPages
                        img={img.HeaderService}
                        title={Data?.title}
                        link={'Service'}
                        linkAr={'خدماتنا'}
                        path='/service'
                        pathAr='/ar/خدماتنا'
                    />
                    <Container className='mt-5'>
                        <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>

                            <Col xl={6} lg={6} md={6} sm={12}>
                                <div className="app__blogs-details-centent mb-5" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <Component.Title
                                        duration_p={0.5}
                                        width={'w-100'}
                                        title={Data?.title}
                                        textStyle={'text-left'}
                                        text={Data?.description}
                                    />

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} className='d-flex justify-content-center align-items-center'>
                                <img src={Data?.image} alt="services" className='w-75' />
                            </Col>
                        </Row>
                    </Container>
                    <div className="mt-5">
                        <Component.Footer />
                    </div>
                </>
                    : <Component.LoadingPage />
            }
        </div>
    )
}

export default ServiceDetails