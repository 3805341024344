import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { FetchDataContext } from '../../context/FetchData';
import { LocalizationContext } from '../../context/LangChange';
import img from './../../assets/Img';


const Whatsapp = () => {
    let { isLang } = useContext(LocalizationContext);
    let { settings } = useContext(FetchDataContext)

    return (
        <div className={`${isLang==="en"?'group_Calling':" whatDark"}    `} >
 
            <a href={`https://wa.me/${settings?.whats}`} target='_blank'rel="noreferrer">
                <motion.div
                    className="phoneIcons round d-flex justify-content-center align-items-center "
                    whileHover={{rotate: 360, y: -5, scale: 1.2 }}
                    transition={{ duration: 0.4 }}
                >
                    <img src={img.Whatsapp } alt="" />
                </motion.div>
            </a>

        </div>
    )
}

export default Whatsapp