import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { BlogsServices } from '../../Services/BlogsServices'
import LocalizationData from '../../Translate/LocalizationData'
import img from '../../assets/Img'
import Component from '../../constants/Component'
import { LocalizationContext } from '../../context/LangChange'
import './blogs.scss'
import { useLocation } from 'react-router-dom'
const Blogs = () => {

    let { isLang } = useContext(LocalizationContext)
    const [data, setData] = useState([])
    const [BlogsData, setBlogsData] = useState([])
    const [loading, setloading] = useState(false)
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);
  
    useEffect(() => {
        window.scroll(0, 0)
        const timeOut = setTimeout(() => {
            setloading(false)
            BlogsServices(isLang).then(({ data }) => {
                if (data?.status === 200) {
                    setloading(true)
                    setData(data?.data)
                    setBlogsData(data?.data?.clients)
                }
            }).catch((error) => {
            });
        }, 200);
        return () => clearTimeout(timeOut);
    }, [isLang])
    const pageTitle = pathname
    ?.replace('/ar/', '')
    ?.replace('/blogs', 'Blogs')
    ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
    ?.replace(/-/g, ' '); // Replace all hyphens with spaces


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            {
                loading ?
                    <>
                        <Component.HeaderPages
                            img={img.Headerblogs}
                            title={pageTitle}
                            text={data?.section_content}
                        />
                        <div className="app__blogs">
                            <Container dir={isLang === "en" ? 'ltr' : 'rtl'}>

                                <div className="app_blogs-content">
                                    <Row>
                                        {
                                            BlogsData?.map((item, index) => (
                                                <Col key={index} xl={4} lg={4} md={6} sm={12} >
                                                    <motion.img
                                                        src={item?.image}
                                                        width={'100%'}
                                                        height={'200px'}
                                                        alt="img about"
                                                        whileInView={{ opacity: [0, 1], y: [100, 0] }}
                                                        transition={{ duration: 0.6, delay: 0.5, ease: 'linear' }}
                                                        style={{ borderRadius: '5px', overflow: 'hidden' }}
                                                    />
                                                    <motion.div
                                                        whileInView={{ opacity: [0, 1], delay: 1, y: [50, 0] }}
                                                        transition={{ duration: 0.6, delay: 1, ease: 'linear' }}
                                                    >
                                                        <div className="content">
                                                            <div className="header">
                                                                <h5>{item?.short_title?.split(" ")?.slice(0, 2)?.join(" ")} </h5>
                                                            </div>
                                                            <div className="body">
                                                                <p>{item?.short_description?.split(" ")?.slice(0, 10)?.join(" ")} {'...'} </p>
                                                            </div>
                                                            <Component.BtnSeeMore fontSize={'10px'} text={LocalizationData[isLang]?.btnDetails} padding={'2px 10px'} delay={0}
                                                                path={isLang == "en" ? `/blogs/${item.key_word}` : `/ar/المدونات/${item.key_word_ar}`}
                                                                flexDirection="column" justifyContent="flex-end" widthBtn={'100px'} />

                                                        </div>
                                                    </motion.div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </Container>
                            <div className="mt-5">
                                <Component.Footer />
                            </div>
                        </div>
                    </>
                    :
                    <Component.LoadingPage />
            }
        </>
    )
}

export default Blogs