import Icons from "../../constants/Icons";

const routes = [
    {
        path: "/ar",
        nameEn: "Home",
        nameAr: "الرئيسه",
        icon: <Icons.Home className="logoSvg" style={{ width: 20 }} />,
    },

    {
        path: "/ar/نبذة-عنا",
        nameEn: "about Us",
        nameAr: "من نحــن ",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />,
    },

    {
        path: "/ar/مشاريعنا",
        nameEn: "Portfolio",
        nameAr: "أعمالنا",
        icon: <Icons.Projects className="logoSvg" style={{ width: 20 }} />,
    },
    {
        path: "/ar/خدماتنا",
        nameEn: "Service",
        nameAr: "خدماتنا ",
        icon: <Icons.Service className="logoSvg" style={{ width: 20 }} />,
    },


    // {
    //     path: "/jobs",
    //     nameEn: "jobs",
    //     icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />,
    // },

    {
        path: "/ar/عملائنا",
        nameEn: "our cliEnt",
        nameAr: "عملائنا",
        icon: <Icons.Clients className="logoSvg" style={{ width: 20 }} />,
    },

    {
        path: "/ar/المدونات",
        nameEn: "Blogs",
        nameAr: "مقالات",
        icon: <Icons.Blogs className="logoSvg" style={{ width: 20 }} />,
    },
    {
        path: "/ar/وظائف",
        nameEn: "Jobs",
        nameAr: "الوظائف ",
        icon: <Icons.Jops className="logoSvg" style={{ width: 20 }} />,
    },
    {
        path: "/ar/اتصل-بنا",
        nameEn: "Contact Us",
        nameAr: "أتصل بنا ",
        icon: <Icons.ContactUs className="logoSvg" style={{ width: 20 }} />,
    },

];

export default routes