import axios from "axios";

 const BlogsServices = async (values) => {
    const url = `${process.env.REACT_APP_API_URL}/home/blogs`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': values,
        }
    }).catch((err) => {
    })
    return data
};

 const BlogsDetailsServices = async (values, id) => {
    const url = `${process.env.REACT_APP_API_URL}/home/blogs/${id}`;
    let data = await axios.get(url, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'application/json',
            'Accept-Language': values,
        }
    }).catch((err) => {
    })
    return data
};
export { BlogsServices, BlogsDetailsServices }