import React, { useContext } from 'react'
import Icons from './../../constants/Icons';
import { FetchDataContext } from '../../context/FetchData';

const Message = () => {
    let { settings } = useContext(FetchDataContext)

    return (
        <div className='group__conect  w-100  gap-3'>
            <div className="email">
                <Icons.Email />
                <a rel="noreferrer" target="_blank" href={`mailto: ${settings?.email}`}>{settings?.email}</a>
            </div>

            <div className="callphone">
                <Icons.CallBold />
                <a dir='ltr' rel="noreferrer" target="_blank" href={`tel:${settings?.phone}`}>
                    {'+ '}{settings?.whats?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}
                </a>
            </div>

            <div className="callphone">
                <Icons.Telephone style={{ width: '22px', height: 'fit-content' }} />
                <a dir='ltr' rel="noreferrer" target="_blank" href={`tel:${settings?.phone}`}>
                    {'+ '}{settings?.phone?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}

                </a>
            </div>
        </div>
    )
}

export default Message

