// ==============================Sidebar======================================
import { ReactComponent as Menu } from '../assets/SVG/menu.svg';
import { ReactComponent as Close } from '../assets/SVG/Close.svg';
import { ReactComponent as Home } from '../assets/SVG/Home.svg';
import { ReactComponent as ContactUs } from '../assets/SVG/ContactUs.svg';
import { ReactComponent as Clients } from '../assets/SVG/Clients.svg';
import { ReactComponent as Projects } from '../assets/SVG/Projects.svg';
import { ReactComponent as Aboutus } from '../assets/SVG/aboutus.svg';
import { ReactComponent as Service } from '../assets/SVG/Service.svg';
import { ReactComponent as Service1 } from '../assets/SVG/service2-digital-marketing.svg';
import { ReactComponent as Service3 } from '../assets/SVG/service3-web.svg';
import { ReactComponent as Service4 } from '../assets/SVG/service4-Identity.svg';
import { ReactComponent as Service5 } from '../assets/SVG/service5-Event Organization.svg';
import { ReactComponent as Service6 } from '../assets/SVG/service6- Visual Producrion.svg';
import { ReactComponent as Email } from '../assets/SVG/email.svg';
import { ReactComponent as CallBold } from '../assets/SVG/CallBold.svg';
import { ReactComponent as Cloud } from '../assets/SVG/cloud-connection.svg';
import { ReactComponent as Mobile } from '../assets/SVG/mobile.svg';
import { ReactComponent as Call } from '../assets/SVG/Call outline.svg';
import { ReactComponent as Message } from '../assets/SVG/message outline.svg';
import { ReactComponent as Email2 } from '../assets/SVG/message outline copy.svg';
import { ReactComponent as HomeOutline } from '../assets/SVG/Home Outline.svg';
import { ReactComponent as LangOutline } from '../assets/SVG/Lang Icons.svg';
import { ReactComponent as Blogs } from '../assets/SVG/Blogs.svg';
import { ReactComponent as Jops } from '../assets/SVG/Jops.svg';
import { ReactComponent as Line } from '../assets/SVG/line.svg';
import { ReactComponent as Rate } from '../assets/SVG/rate.svg';
import { ReactComponent as Rateoutlit } from '../assets/SVG/rate-outlit.svg';
import { ReactComponent as Upload } from '../assets/SVG/upload.svg';
import { ReactComponent as Location } from '../assets/SVG/location.svg';
import { ReactComponent as R } from './../assets/Images/Service/Frame.svg';
import { ReactComponent as Youtube } from './../assets/SVG/youtube.svg';
import { ReactComponent as TextLeft } from './../assets/Images/Service/We just want your trust in us.svg';
import { ReactComponent as TextRight } from './../assets/SVG/ثقتك بنا هي المفتاح لبداية الابداع.svg';
import { ReactComponent as Telephone } from './../assets/SVG/old-telephone-ringing-svgrepo-com.svg';
import { ReactComponent as TopArrow } from './../assets/SVG/Toplinear.svg';


// import { ReactComponent as Facebook } from '../assets/SVG/facebook.svg';
// import { ReactComponent as Twitter } from '../assets/SVG/twitter.svg';
// import { ReactComponent as Instegram } from '../assets/SVG/instegram.svg';
// import { ReactComponent as Linkedin } from '../assets/SVG/linkedin.svg';
import { ReactComponent as Whatsapp } from '../assets/SVG/whatsapp.svg';
import { ReactComponent as Phone } from '../assets/SVG/call.svg';
import Twitter from './../assets/SVG/Twitter';
import Facebook from './../assets/SVG/Facebook';
import Instagram from './../assets/SVG/Instagram';
import Linkedin from './../assets/SVG/Linkedin';

const Icons = {
    // ==============================Sidebar======================================
    Menu,
    R,
    Telephone,
    TopArrow,
    TextLeft,
    Close,
    Service1,
    Service3,
    Service4,
    Service5,
    Service6,
    Home,
    Aboutus,
    ContactUs,
    Clients,
    Projects,
    Service,
    CallBold,
    Jops,
    Blogs,
    Line,
    Facebook,
    Twitter,
    Instagram,
    Linkedin,
    Whatsapp,
    Phone,
    Email,
    Cloud,
    Mobile,
    Call,
    Message,
    HomeOutline,
    LangOutline,
    Rate,
    Rateoutlit,
    Upload,
    Location,
    Email2,
    Youtube,
    TextRight
}

export default Icons;
