import React from 'react'
import { Link } from 'react-router-dom'

const SeoSlider = ({ Carousel, Button, Row, Col, isLang, data }) => {
    return (
        <div className='slider_container'>
            <Carousel className="app__slider-home" interval={3000} fade showArrows={false} prevIcon={null} nextIcon={null}>
                {
                    data?.map((item, index) => (
                        <Carousel.Item key={index} className="w-100" dir={isLang == "en" ? 'ltr' : 'rtl'}>
                            <Row>
                                <Col xl={6} lg={6} md={8} sm={12} className='d-flex align-items-center'>
                                    <div className="app__seo">
                                        <h5>{item?.title}</h5>
                                        <p>{item?.description}</p>
                                        <Link to={`${isLang === "en" ? '/contactus' : '/ar/اتصل-بنا'}`}>
                                            <Button> {isLang == "en" ? 'Request Now' : 'اطلب الآن'} </Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={4} sm={12} className='d-flex slider_img justify-content-end mt-5   align-items-start'>
                                    <img src={item?.image} width={400} alt="" srcset="" />
                                </Col>
                            </Row>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </div>
    )
}

export default SeoSlider
