import React from 'react';
import { motion } from 'framer-motion';
import './style.scss';

const Loading = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.5 } },
    };

    const letterVariants = {
        hidden: { opacity: 0, y: -20, rotate: 0 },
        visible: { opacity: 1, y: 0, rotate: 360 ,transition:{ duration: 2,type: 'just'} },
    };

    return (
        <motion.div
        dir='ltr'
            className="page__loading-page"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="right">
                <motion.span variants={letterVariants}>R</motion.span>
                <motion.span variants={letterVariants}>i</motion.span>
                <motion.span variants={letterVariants}>g</motion.span>
                <motion.span variants={letterVariants}>h</motion.span>
                <motion.span variants={letterVariants}>t</motion.span>
            </div>
            <span>&nbsp;</span>
            <div className="left">
                <motion.span variants={letterVariants}>Click</motion.span>
            </div>
        </motion.div>
    );
};

export default Loading;
