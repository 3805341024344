import React, { createContext, useContext, useEffect, useState } from 'react';
import SettingsServices from '../Services/SettingsServices';
import { LocalizationContext } from './LangChange';

export const FetchDataContext = createContext([])

function FetchData({ children }) {
    const [settings, setSettings] = useState({});
    let { isLang } = useContext(LocalizationContext)



    useEffect(() => {
        const timeOut = setTimeout(() => {
            SettingsServices(isLang).then(({ data }) => {
                if (data?.status === 200) {
                    setSettings(data?.data)
                }
            }).catch((error) => {
            });
        }, 200);
        return () => clearTimeout(timeOut);
    }, [isLang]);

    return (
        <FetchDataContext.Provider value={{ settings }}>
            {children}
        </FetchDataContext.Provider>
    )
}

export default FetchData