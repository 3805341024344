import { Box, Button } from '@mui/material';
import AOS from 'aos';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const BtnSeeMore = ({ widthBtn,flexDirection, justifyContent, text, path, fontSize, padding }) => {
  
    useEffect(() => {
        AOS.init({
            // once: true, 
        });
        AOS.refresh();
    }, [])
    return (
            <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-easing="linear"

            >
                <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} flexDirection={`${flexDirection}`} justifyContent={`${justifyContent}`}>
                    <Button
                        as={Link}
                        to={path}
                        className='btn__overlay'
                        variant="contained"
                        color="warning" 
                        sx={{
                            textTransform: 'capitalize',
                            width: `${widthBtn} !important`,
                            backgroundColor: '#FA6409',
                            color: '#24282C',
                            '&:hover': {
                                backgroundColor: '#000000',
                                color: '#ffffff',
                            },
                            padding: `${padding}`,
                            fontSize: `${fontSize} !important`
                        }}
                    >
                        {text}
                        
                    </Button>
                </Box>
            </div>

    );
}

export default BtnSeeMore;
