
import { Box, Button, Fade } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../../../assets/Img';
import { LocalizationContext } from '../../../context/LangChange';
import Component from '../../../constants/Component';
import { Link } from 'react-router-dom';
import AOS from 'aos';

const ProjectsWeb = ({ projectsData, page, isLoading }) => {
    const [hoverState, setHoverState] = useState({});
    let { isLang } = useContext(LocalizationContext);

    const handleMouseEnter = (index) => {
        setHoverState((prevState) => ({
            ...prevState,
            [index]: true
        }));
    };

    const handleMouseLeave = (index) => {
        setHoverState((prevState) => ({
            ...prevState,
            [index]: false
        }));
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {

        AOS.init();
        AOS.refresh();
    }, [])
    return (
        <>
            {
                isLoading ?
                    <Row className='mb-3 d-flex justify-content-center align-items-center'>
                        {
                            page === 'home' &&
                            <>
                                {projectsData?.slice(0, 3)?.map((item, index) => (
                                    <>
                                        <Col xl={3} lg={3} md={6} sm={12} key={index}>
                                            <Box
                                                position="relative"
                                                width="100%"
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => handleMouseLeave(index)}
                                                marginTop={'20px'}
                                            >
                                                {
                                                    item?.image ?
                                                        <img
                                                            src={item.image}
                                                            width={'100%'}
                                                            height={'430px'}
                                                            alt="img about"
                                                            data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-out-cubic"
                                                            style={{ borderRadius: '5px', overflow: 'hidden', objectFit: 'cover', objectPosition: '50% 50%' }}
                                                        /> :
                                                        <img src={img.ImageDefult} width={'100%'}
                                                            height={'400px'}
                                                            alt="img about" />
                                                }
                                                <motion.div whileInView={{ opacity: [0, 1] }} transition={{ duration: 0.5, delay: 0.5 }}>
                                                    <Fade in={hoverState[index]} timeout={400}>
                                                        <Box
                                                            position="absolute"
                                                            top={0}
                                                            left={0}
                                                            width="100%"
                                                            height="100%"
                                                            display="flex"
                                                            alignItems="start"
                                                            justifyContent="flex-end"
                                                            bgcolor="rgba(0, 0, 0, 0.6)"
                                                            borderRadius={'5px'}
                                                            flexDirection="column"
                                                            pl={3}
                                                            pr={3}
                                                            pt={4}
                                                            pb={4}
                                                        >

                                                            <Box display="flex" alignItems="start" justifyContent="flex-end" mb={1}>
                                                                <img src={item?.logo} alt="Logo" height={70} width={120} style={{ filter: 'brightness(0) invert(1)', objectFit: 'contain', objectPosition: '50% 50%' }} />
                                                            </Box>
                                                            <Box flexGrow={1} display="flex" gap={'15px'} flexDirection="column" justifyContent="flex-end">
                                                                <span className='title__overlay' >  {item?.title}  </span>
                                                                <span className='body__overlay'> {item?.description?.split(" ")?.slice(0, 10)?.join(" ")} </span>
                                                                <Link to={isLang === "en" ? `/projects/${item.key_word}` : `/ar/مشاريعنا/${item.key_word_ar}`}>
                                                                    <Button style={{ textTransform: 'capitalize', width: '90px', padding: '4px' }} className='btn__overlay' variant="contained" color="warning">
                                                                        {isLang === "en" ? 'Details' : 'تفاصيل'}
                                                                    </Button>
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Fade>
                                                </motion.div>
                                            </Box>
                                        </Col>
                                    </>
                                ))}
                                {
                                    projectsData.length >= 3 &&
                                    <Component.MoreProjects handleClickOpen={handleClickOpen} isLang={isLang} />
                                }
                            </>
                        }

                        {
                            page === 'projects' &&
                            <>
                                {projectsData?.map((item, index) => (
                                    <>
                                        <Col xl={3} lg={3} md={6} sm={12} key={index}>
                                            <Box
                                                position="relative"
                                                width="100%"
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => handleMouseLeave(index)}
                                                marginTop={'20px'}
                                            >
                                                {
                                                    item?.image ?
                                                        <motion.img
                                                            src={item.image}
                                                            width={'100%'}
                                                            height={'430px'}
                                                            alt="img about"
                                                            animate={{ opacity: [0, 1], y: [100, 0] }}
                                                            transition={{ duration: 0.6, ease: 'linear' }}
                                                            style={{ borderRadius: '5px', overflow: 'hidden', objectFit: 'cover', objectPosition: '50% 50%' }}
                                                            className='shadow-sm'
                                                        /> :
                                                        <img src={img.ImageDefult} width={'100%'}
                                                            height={'400px'}
                                                            alt="img about" />
                                                }
                                                <motion.div whileInView={{ opacity: [0, 1] }} transition={{ duration: 0.5, delay: 0.5 }}>
                                                    <Fade in={hoverState[index]} timeout={400}>

                                                        <Box
                                                            position="absolute"
                                                            top={0}
                                                            left={0}
                                                            width="100%"
                                                            height="100%"
                                                            display="flex"
                                                            alignItems="start"
                                                            justifyContent="flex-end"
                                                            bgcolor="rgba(0, 0, 0, 0.6)"
                                                            borderRadius={'5px'}
                                                            flexDirection="column"
                                                            pl={3}
                                                            pr={3}
                                                            pt={4}
                                                            pb={4}
                                                        >
                                                            <Box display="flex" alignItems="start" className='Logo_filter' justifyContent="flex-end" mb={1}>
                                                                <img src={item?.logo} alt="Logo" height={'100%'} width={'100%'} style={{ objectFit: 'contain', objectPosition: '50% 50%', filter: 'brightness(0) invert(1)' }} />
                                                            </Box>
                                                            <Box flexGrow={1} display="flex" gap={'15px'} flexDirection="column" justifyContent="flex-end">
                                                                <span className='title__overlay' >  {item?.title}  </span>
                                                                <span className='body__overlay'> {item?.description?.split(" ")?.slice(0, 10)?.join(" ")} </span>
                                                                <Link to={isLang === "en" ? `/projects/${item.key_word}` : `/ar/مشاريعنا/${item.key_word_ar}`}>
                                                                    <Button style={{ textTransform: 'capitalize', width: '90px', padding: '4px' }} className='btn__overlay' variant="contained" color="warning">
                                                                        {isLang === "en" ? 'Details' : 'تفاصيل'}
                                                                    </Button>
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Fade>
                                                </motion.div>
                                            </Box>
                                        </Col>
                                    </>
                                ))}
                            </>
                        }
                    </Row >
                    :
                    <>
                        <Component.LoaderBots />
                    </>
            }
            {/*   {
                page === 'home' && */}
            <Component.ModalProjects open={open} projectsData={projectsData} isLang={isLang} handleClose={handleClose} />
            {/* } */}
        </>
    )
}

export default ProjectsWeb
