import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { BlogsDetailsServices } from '../../Services/BlogsServices';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Helmet } from 'react-helmet-async';
const BlogsDetails = () => {
    let { isLang } = useContext(LocalizationContext)
    let { id } = useParams()
    const location = useLocation();
    const pathname = decodeURIComponent(location.pathname);
    const [data, setData] = useState([])
    const [loading, setloading] = useState(false)
    const [imageIndex, setImageIndex] = useState(0)

    useEffect(() => {
        window.scroll(0, 0)
        const timeOut = setTimeout(() => {
            setloading(false)
            BlogsDetailsServices(isLang, id).then(({ data }) => {
                if (data?.status === 200) {
                    setloading(true) 
                    setData(data?.data)
                }
            }).catch((error) => {
            });
        }, 200);
        return () => clearTimeout(timeOut);
    }, [isLang, id])

    const images = data?.images?.map((item) => ({
        original: item.image,
        thumbnail: item.image,
        originalAlt: item.title,
        thumbnailAlt: item.title,
        description: item.title,
        id: item.id,
    }));
    const renderCustomThumbInner = (item) => {
        return (
            <div className="image-gallery-thumbnail-inner  " >
                <img
                    src={item.thumbnail}
                    alt={item.thumbnailAlt}
                    className="image-gallery-thumbnail-image"
                />
            </div>
        );
    };
    const renderItem = (item) => {
        return (

            <img
                src={item.original}
                alt={item.originalAlt}
                style={{
                    width: '500px',
                    height: '500px'
                }}
            />
        );
    };

    const pageTitle = pathname
        ?.replace('/ar/', '')
        ?.replace('/blogs', 'Blogs')
        ?.replace('/', ' | ')
        ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
        ?.replace(/-/g, ' ')
        ?.replace(/_/g, ' ')

    return (
        <div className='app__services_details'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            {
                loading ?
                    <>
                        <Component.HeaderPages
                            img={data.image}
                            title={pageTitle}
                            link={'Blogs'}
                            linkAr={'المدونات'}
                            path='/blog'
                            pathAr='/ar/المدونات'
                        />
                        <Container>
                            <div className="app__blogs-details-centent mb-5" dir={isLang === "en" ? 'ltr' : 'rtl'}>


                                <div className="app__title " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <h1
                                        // style={{ fontSize: '16px' }}
                                        className={` text-left   mb-3 fw-bold`}
                                        data-aos="flip-left"
                                        data-aos-duration="2000"
                                        data-aos-easing="ease-out-cubic"
                                    >
                                        {data?.title}
                                    </h1>


                                    <div className='blog_details_data' dangerouslySetInnerHTML={{ __html: data?.description }} />

                                </div>
                            </div>
                        </Container>

                        <Container  >
                            <Row className="mb-3 pt-5 mt-5 d-flex justify-content-center align-items-center">
                                <ReactImageGallery
                                    items={images}
                                    infinite={true}
                                    autoPlay={true}
                                    showIndex={true}
                                    thumbnailPosition={'bottom'}
                                    additionalClass="custom-image-gallery"
                                    renderItem={renderItem}

                                    renderThumbInner={renderCustomThumbInner} // استدعاء الدالة الجديدة للـ thumbnail
                                    showFullscreenButton={true} // يمكنك تعطيل أزرار التكبير والتصغير إذا أردت.
                                    showPlayButton={true} // يمكنك تعطيل زر التشغيل التلقائي إذا أردت.
                                    itemsPerRow={1} // تعيين عدد الصور المراد عرضها لكل thumbnail
                                />

                            </Row>

                        </Container>
                        <div className="mt-5">
                            <Component.Footer />
                        </div>
                    </> : <Component.LoadingPage />
            }
        </div>
    )
}

export default BlogsDetails