import AOS from 'aos';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ServicesHome } from '../../../Services/ProjectServices';
import Component from '../../../constants/Component';
import Icons from '../../../constants/Icons';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';

const WhatWeDo = ( ) => {
    let { isLang } = useContext(LocalizationContext);
    useEffect(() => {
        AOS.init({
            // once: true, 
        });
        AOS.refresh();
    }, [])

    const [data, setData] = useState([])
    const [services, setServices] = useState([])
    const [isLoading, setisLoading] = useState(false)
  
    useEffect(() => { 
            const timeOut = setTimeout(() => {
                setisLoading(false)
                ServicesHome(isLang).then(({ data }) => {
                    if (data?.status === 200) {
                        setData(data?.data)
                        setServices(data?.data?.services) 
                        setisLoading(true)
                    }
                }).catch((error) => {
                });
            }, 200);
            return () => clearTimeout(timeOut);
    }, [isLang])
    return (

        <div className="app__WhatWeDo "  >
            {
                isLoading &&
                <>
                    <Container fluid>
                        <Component.Title
                            duration_p={1.5}
                            title={data?.section_title}
                            text={data?.section_content}
                            textStyle={'text-center'}
                        />
                        <div className="img__RightClick mt-5">
                            <div data-aos="fade-up" data-aos-duration="1500" data-aos-easing={'ease-in'}>
                                <Icons.R />
                            </div>

                            <div className='servie_1' data-aos={'fade-up-right'} data-aos-duration="1500" data-aos-easing={'ease-in'} >
                                <h6 className='title__service' dir={isLang === "en" ? 'ltr' : 'rtl'}> {services[5].title?.split(' ').map((word, index) => <React.Fragment key={index}> {word}<br /> </React.Fragment>)} </h6>
                                <img src={services[5]?.image} className='w-100' alt="" />
                            </div>
                            <div className='servie_2 ' data-aos={'fade-up-left'} data-aos-duration="1500" data-aos-easing={'ease-in'}>
                                <h6 className='title__service' dir={isLang === "en" ? 'ltr' : 'rtl'}> {services[0].title?.split(' ').map((word, index) => <React.Fragment key={index}> {word}  </React.Fragment>)}</h6>
                                <img src={services[0]?.image} className='w-100' alt="" />
                            </div>
                            <div className='servie_3 ' data-aos={'fade-up-right'} data-aos-duration="1500" data-aos-easing={'ease-in'} >
                                <h6 className='title__service' dir={isLang === "en" ? 'ltr' : 'rtl'}>{services[2].title?.split(' ').map((word, index) => <React.Fragment key={index}> {word}<br />  </React.Fragment>)}</h6>
                                <img src={services[2]?.image} className='w-100' alt="" />
                            </div>
                            <div className='servie_4 ' data-aos={'fade-up-right'} data-aos-duration="1500" data-aos-easing={'ease-in'} >
                                <h6 className='title__service' dir={isLang === "en" ? 'ltr' : 'rtl'}>{services[3].title?.split(' ').map((word, index) => <React.Fragment key={index}> {word}<br />   </React.Fragment>)}</h6>
                                <img src={services[3]?.image} className='w-100' alt="" />
                            </div>
                            <div className='servie_5 ' data-aos={'fade-up-left'} data-aos-duration="1500" data-aos-easing={'ease-in'} >
                                <h6 className='title__service' dir={isLang === "en" ? 'ltr' : 'rtl'}>{services[1].title?.split(' ').map((word, index) => <React.Fragment key={index}>  {word}<br /> </React.Fragment>)} </h6>
                                <img src={services[1]?.image} className='w-100' alt="" />
                            </div>

                        </div>
                    </Container>

                    <div className="text-p-left">
                        {
                            isLang === "en" &&
                            <Icons.TextLeft />
                        }
                    </div>


                    <div className="text-p-right">
                        {
                            isLang === "ar" &&
                            <Icons.TextRight />
                        }
                    </div>


                    {/*         <div className="aniamtion2">
                        <img src={img.animationPNG1} alt="" />
                    </div> */}
                </>
            }
        </div>

    )
}

export default WhatWeDo
