let LocalizationFooter = {
    "en": {
        follow: 'Follow Us',
        TitleQuickLinks: 'quick links',
        QuickLinks: [
            { path: '/home', pathAr: '/ar/الصفحة-الرئيسية', text: 'Home' },
            { path: '/about-us', pathAr: '/ar/نبذة-عنا', text: 'about us' },
            { path: '/projects', pathAr: '/ar/مشاريعنا', text: 'portfolio' },
            { path: '/clients', pathAr: '/ar/عملائنا', text: 'our client' },
            { path: '/blogs', pathAr: '/ar/المدونات', text: 'blogs' },
            { path: '/jobs', pathAr: '/ar/وظائف', text: 'career ' },
        ],
        TitleService: 'Service',
        service: [
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Event Organization', type: 'Event' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Visual Producrion', type: 'visualProduction' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Web Development', type: 'web' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Account Management ', type: 'mobilApp' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Digital Marketing', type: 'digitalMarketing' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'Visual Identity', type: 'VisualIdentity' },
        ],
        TitleContactUS: 'Contact Us'
    },

    "ar": {
        follow: ' تــابعنــا هـــــنا',
        TitleQuickLinks: 'تنقل سريع',

        QuickLinks: [
            { path: '/home', pathAr: '/ar/الصفحة-الرئيسية', text: 'الرئيسيه' },
            { path: '/about-us', pathAr: '/ar/نبذة-عنا', text: '  من نحن' },
            { path: '/projects', pathAr: '/ar/مشاريعنا', text: 'أعمالنا' },
            { path: '/clients', pathAr: '/ar/عملائنا', text: 'عملائنا  ' },
            { path: '/blogs', pathAr: '/ar/المدونات', text: 'مقالات' },
            { path: '/jobs', pathAr: '/ar/وظائف', text: 'وظائفنا  ' },
        ],
        TitleService: 'خدماتنا',
        service: [
            'التسويق الرقمي',
            "الإنتاج الظاهري",
            "تنظيم الأحداث",
            "تطوير المواقع الالكترونيه ",
            "هوية بصرية"
        ],

        service: [
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'تنظيم الأحداث', type: 'Event' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'الإنتاج المرئي', type: 'visualProduction' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'تطوير الشبكة', type: 'web' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'التسويق الرقمي', type: 'digitalMarketing' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: 'الهوية المرئية', type: 'VisualIdentity' },
            { path: '/service', pathAr: '/ar/خدماتنا', text: '  إدارة الحسابات ', type: 'mobilApp' },

        ],
        TitleContactUS: 'أتصل بنا',
    }
}

export default LocalizationFooter