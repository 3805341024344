import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ProjectServices, ServicesHome } from '../../Services/ProjectServices'
import img from '../../assets/Img'
import Component from '../../constants/Component'
import { LocalizationContext } from '../../context/LangChange'
import './project.scss'
import { useLocation } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // your primary color
    },
    secondary: {
      main: '#FA6409', // your new secondary color
    },
  },
});

const Projects = () => {
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const [value, setValue] = useState('visual_production');
  let { isLang } = useContext(LocalizationContext);

  // Visual Identity
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [Data, setData] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [isLoading, setisLoading] = useState(false)


  useEffect(() => {

    const timeOut = setTimeout(() => {
      setisLoading(false)
      setProjectsData([])
      ProjectServices(isLang, value).then(({ data }) => {
        if (data?.status === 200) {
          setData(data?.data)
          setProjectsData(data?.data?.projects);
          setisLoading(true)
        }
      }).catch((error) => {
      });
    }, 200);
    return () => clearTimeout(timeOut);
  }, [isLang, value])

  const [services, setServices] = useState([])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setisLoading(false)
      ServicesHome(isLang).then(({ data }) => {
        if (data?.status === 200) { 
          setServices(data?.data?.services)
          setisLoading(true)
        }
      }).catch((error) => {
      });
    }, 0);
    return () => clearTimeout(timeOut);
  }, [isLang])
  const pageTitle = pathname
    ?.replace('/ar/', '')
    ?.replace('/projects', 'Projects')
    ?.replace('/', ' | ')
    ?.replace(/^\/|\/$/g, '') // Remove leading and trailing slashes
    ?.replace(/-/g, ' '); // Replace all hyphens with spaces

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Component.HeaderPages
        img={img.HeaderAboutUS}
        title={Data?.section_title}
        textStyle={'text-center'}
        text={Data?.section_content}
      />
      <div className='    overflow-hidden  mb-5'  >

        <>
          <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                // centered={true}
                aria-label="scrollable auto tabs example"
              >
                {
                  services.map((item, index) => (
                    <Tab style={{ textTransform: 'capitalize', justifyContent: 'center', fontSize: '17px' }} value={item.value} label={item.title} variant={value === item.title ? 'contained' : 'standard'} sx={{ justifyContent: 'center' }} />
                  ))
                }
              </Tabs>
            </Box>

            {value === 'web_development' && (
              <Box sx={{ p: 2, overflow: 'hidden' }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}

            {value === 'account_management' && (
              <Box sx={{ p: 2 }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}

            {value === 'digital_marketing' && (
              <Box sx={{ p: 2 }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}

            {value === 'event_organization' && (
              <Box sx={{ p: 2 }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}

            {value === 'visual_production' && (
              <Box sx={{ p: 2 }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}

            {value === 'visual_identity' && (
              <Box sx={{ p: 2 }}>
                <Component.ProjectsWeb page='projects' projectsData={projectsData} isLoading={isLoading} />
              </Box>
            )}
          </ThemeProvider>
        </>
      </div>
      <Component.Footer />
    </>
  )
}

export default Projects