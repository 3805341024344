import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap'
import AOS from 'aos';

const MoreProjects = ({ handleClickOpen, isLang }) => {
    useEffect(() => { 
        AOS.init();
        AOS.refresh();
    }, [])
    return (
        <Col Col xl={3} lg={3} md={6} sm={12} dir='rtl '>
            <Box
                position="relative"
                width="100%"
                marginTop={'20px'}
                className="property-1-default"
                data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-out-cubic"
            >
                <div className="our-portfolio  ">{isLang === "en" ? 'Our Portfolio' : 'مشاريعنا'}</div>
                <div className={`${isLang === 'en' ? 'gallery' : 'galleryAr'}`}>
                    <div className="know-more " dir='rtl'>
                        <div className="line-34"></div>
                        <span onClick={handleClickOpen}>
                            {isLang === "en" ? 'See more' : 'رؤية المزيد'}
                        </span>
                    </div>
                </div>

            </Box>
        </Col>
    )
}

export default MoreProjects
